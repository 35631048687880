.center {
  height: 8vh;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}
.wave {
  width: 8px;
  height: 50px;
  /* background: linear-gradient(45deg, rgb(0, 166, 255), rgb(191, 229, 255)); */
  margin: 5px;
  /* animation: wave 1s linear infinite;
    border-radius: 50px; */
}
/* .wave:nth-child(2) {
    animation-delay: 0.1s;
  }
  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }
  .wave:nth-child(4) {
    animation-delay: 0.3s;
  }
  .wave:nth-child(5) {
    animation-delay: 0.4s;
  }
  .wave:nth-child(6) {
    animation-delay: 0.5s;
  }
  .wave:nth-child(7) {
    animation-delay: 0.6s;
  }
  .wave:nth-child(8) {
    animation-delay: 0.7s;
  }
  .wave:nth-child(9) {
    animation-delay: 0.8s;
  }
  .wave:nth-child(10) {
    animation-delay: 0.9s;
  } */
