.custom_card {
  /* padding: 0; */
  border-radius: 15px;
  position: relative;
  transition: 0.25s ease-in-out;
  backface-visibility: hidden;
}

.custom_card.comment_card {
  /* border: 0; */
  /* border-bottom: 1px solid var(--color-gray-light4); */
  border-radius: 15px;
  background-color: transparent;
  /* background-color: var(--color-gray-light6); */
  /* box-shadow: 0 8px 15px rgba(120, 120, 120, 0.2); */
}

.custom_card.comment_card .media_icon_wrap {
  width: 36px !important;
  height: 36px !important;
}

.custom_card.comment_card .card-header {
  background-color: transparent;
  padding: 10px 10px;
}
.custom_card.comment_card .card-body {
  padding: 10px 10px;
}
.custom_card.comment_card .card-header .card-title {
  font-size: 14px;
  font-weight: var(--fw-sb);
}

/* .dark_theme :global(.card) {
  background-color: #333 !important;
} */

.custom_card .ribbon_type {
  width: auto;
}

.custom_card .ribbon_type.top_right {
  position: absolute;
  top: -6px;
  right: 15px;
}
.custom_card .ribbon_type.top_left {
  position: absolute;
  top: 10px;
  left: -6px;
}
.custom_card .card-body {
  padding: 15px;
}
.custom_card .card-header {
  padding: 15px;
  /* background-color: transparent; */
  background-color: var(--color-gray-light7);
  border: 0px;
}

/* .dark_theme .custom_card .card-header {
  background-color: var(--color-gray25) !important;
} */

/* .dark_theme .custom_card.comment_card .card-header,
.dark_theme .custom_card.comment_card {
  background-color: var(--color-gray3);
} */

.custom_card .media {
  display: flex;
  align-items: center;
}
.custom_card .media_icon_wrap,
.custom_card .media_img_wrap {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--color-gray-light1);
  border: 3px solid #fff;
  box-shadow: 0 2px 8px rgba(120, 120, 120, 0.3);
  position: relative;
  text-align: center;
}

.custom_card .profile_img,
.custom_card .media_img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  /* border: 3px solid var(--color-gray-light5); */
}

.dark_theme .custom_card.main_card > .card-header {
  border-color: var(--color-gray3a);
}

.dark_theme .custom_card .card-header {
  border-color: var(--color-gray4);
}
.custom_card .card-header .card-title {
  font-weight: var(--fw-b);
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 1.35;
}
.custom_card .card-header .card-subtitle {
  color: var(--color-gray7);
  font-size: 13px;
  line-height: 1.35;
  font-weight: var(--fw-n);
}

.custom_card .media_body {
  flex-grow: 1;
}

.custom_card.add_comment_card {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-top: 1px solid var(--color-gray-light4) !important;
  background-color: #fff;
  border-radius: 3px;
  /* border-radius: 3px; */
}

.dark_theme .custom_card.add_comment_card {
  border-top: 1px solid var(--color-gray-light3) !important;
  background-color: var(--color-gray25);
}

@media screen and (max-width: 767px) {
  .custom_card .media_img_wrap,
  .custom_card .media_icon_wrap {
    flex-shrink: 0;
    width: 46px;
    height: 46px;
    border: 2px solid #fff;
    box-shadow: 0 2px 4px rgba(120, 120, 120, 0.3);
  }
  .custom_card .media_icon {
    width: 26px;
  }
}