.web_profile_card {
  height: 100%;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  transition: 0.25s ease-in-out;
}
.card-img {
  flex-shrink: 0;
  width: 100%;
  padding: 15px;
}
.card-img img {
  width: 70%;
}
.web_profile_card :global(.card-body) {
  padding: 15px;
}
.web_profile_card :global(.card-body) h6 {
  font-size: 18px;
  font-weight: var(--fw-n);
  margin-bottom: 5px;
  transition: 0.25s ease-in-out;
}
.web_profile_card :global(.card-body) small {
  color: var(--color-gray6);
  display: block;
  font-size: 85%;
  line-height: 1.35;
}
.web_profile_card :global(.card-footer) {
  background-color: transparent;
  padding: 10px 15px 20px;
}
.web_profile_card :global(.btn_theme) {
  margin-bottom: 15px;
}

.web_profile_card:hover {
  transform: translateY(-3px);
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15);
}
.web_profile_card:hover h6 {
  color: var(--color-primary);
}
