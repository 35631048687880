.searched_group_card {
  overflow: hidden;
}
.searched_group_card .card-header {
  background-color: transparent;
  padding-bottom: 5px;
}
.searched_group_card .media_icon_wrap {
  background-color: var(--color-primary-lighter);
}
.searched_group_card .media_icon_wrap svg {
  font-size: 30px;
  color: var(--color-secondary);
}
