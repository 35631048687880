.video-modal .video_wrapper {
    position: relative;
    /* height: 360px; */
    border: 0;
  }

  .video-modal .modal-body > .btn_theme {
    opacity: 0;
    visibility: hidden;
  }
  .video-modal .modal-body:hover .btn_theme {
    opacity: 1;
    visibility: visible;
  }
  
  .video-modal .video_wrapper iframe {
    width: 100%;
    height: 100%;
    -webkit-object-fit: contain;
    object-fit: contain;
    -webkit-object-position: top;
    object-position: top;
    border: 0px;
  }