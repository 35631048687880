.comment_list_wrap {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px 10px 120px;
}
.comment_list_wrap::-webkit-scrollbar {
  /* display: none; */
  width: 4px;
  background-color: transparent;
}

/* .comment_list_wrap:hover::-webkit-scrollbar {
  display: block;
} */

.comment_list_wrap::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: var(--color-gray-light2);
}

.comment_list_wrap::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-gray-light1);
}

.comment_list_wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0),
    inset -1px -1px 1px rgba(0, 0, 0, 0);
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0),
    inset -1px -1px 1px rgba(0, 0, 0, 0);
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.1);
}
