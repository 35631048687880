.consultant_card {
  padding: 0;
  /* border-radius: 4px; */
  position: relative;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.consultant_card .consult_icon {
  position: absolute;
  right: 15px;
  top: 15px;
  /* background-color: var(--color-golden-lighter); */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
  border-radius: 50%;
}
.consultant_card .consult_icon img {
  height: 30px;
  filter: drop-shadow(0 0 0 black);
}
.dark_theme .consultant_card .consult_icon img {
  filter: brightness(0) invert(1);
}

.card-header.consultant_card_header {
  padding: 15px 15px 5px;
  /* background-color: var(--color-gray-light7); */
  background-color: transparent;
  border: 0px;
}
.consultant_card .card-body {
  padding: 6px 15px 15px;
  /* background-color: var(--color-primary-lighter2); */
}

.consultant_card .card-body .specialty-wrap {
  padding-left: 70px;
}

.consultant_card .media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.consultant_card .card_title {
  font-weight: var(--fw-b);
  margin-bottom: 2px;
  font-size: 15px;
  line-height: 1.5;
}
.consultant_card .media_body {
  position: relative;
  -ms-flex: 1;
  flex: 1;
}

.consultant_card .btn_expand {
  margin-left: 95px;
}

.consultant_card .btn_wrap {
  bottom: 0;
  right: 0;
  left: 0;
  padding: 8px 0 5px;
}

.consultant_card .list-group {
  height: 50px;
  overflow: hidden;
  padding-left: 75px;
  transition: 0.2s linear;
}

.consultant_card .list-group-item {
  border: 0px;
  background-color: transparent;
  padding: 2px 0px 2px 22px;
  color: var(--color-gray6);
}
.consultant_card .list-group-item strong {
  font-weight: var(--fw-b);
}
.consultant_card .list-group-item > svg {
  position: absolute;
  left: 0;
  top: 7px;
  /* margin-right: 1px; */
  font-size: 16px;
  color: var(--color-success-dark);
}

.consultant_card .expand {
  height: auto;
}

.consultant_card .cost-wrap {
  padding: 6px 5px;
  background-color: var(--color-success-lighter2);
  border-radius: 10px;
}

.dark_theme .consultant_card .btn_theme.btn_link {
  color: inherit;
}
