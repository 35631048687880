.dashboard_nav_card .card-body {
  padding: 15px 10px;
}
.dashboard_nav_card ul li {
  margin-bottom: 5px;
  width: 100%;
  padding: 10px;
  font-size: 15px;
  color: var(--color-gray6);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  transition: 0.2s ease;
}

.dark_theme .dashboard_nav_card ul li {
  color: var(--color-gray-light4);
}
.dashboard_nav_card ul li .custom_tag {
  position: absolute;
  right: 5px;
  top: 5px;
  bottom: 0;
  /* border-radius: 50%; */
  /* padding: 0; */
  /* width: 30px; */
  height: 24px;
  font-size: 12px;
  /* line-height: 30px; */
  line-height: 1;
  margin: auto;
}
.dashboard_nav_card ul li .badge.bg-danger {
  position: absolute;
  right: 10px;
  background-color: var(--color-danger-dark) !important;
}
.dashboard_nav_card ul li .icon_container {
  width: 40px;
  margin-right: 10px;
}
.dashboard_nav_card ul li img {
  height: 25px;
  transition: 0.2s ease;
}

.dark_theme .dashboard_nav_card ul li img {
  filter: brightness(0) invert(1);
}

.dashboard_nav_card ul li:hover,
.dashboard_nav_card ul li.active {
  background-color: var(--color-primary-lighter);
}

.dark_theme .dashboard_nav_card ul li:hover,
.dark_theme .dashboard_nav_card ul li.active {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.dashboard_nav_card ul li:hover img,
.dashboard_nav_card ul li.active img {
  /* filter: brightness(0) invert(1); */
  transform: scale(1.1);
}

.dark_theme .dashboard_nav_card ul li:hover img,
.dark_theme .dashboard_nav_card ul li.active img {
  filter: brightness(0) invert(0.95);
  transform: scale(1.1);
}
