.leaderboard_member_card {
  padding: 15px;
  overflow: hidden;
  position: relative;
}
.leaderboard_member_card:hover {
  -webkit-transform: scale(0.99) translateY(-2px);
  -moz-transform: scale(0.99) translateY(-2px);
  transform: scale(0.99) translateY(-2px);
  -webkit-box-shadow: 0 5px 15px -10px rgba(20, 20, 120, 0.3);
  -moz-box-shadow: 0 5px 15px -10px rgba(20, 20, 120, 0.3);
  box-shadow: 0 5px 15px -10px rgba(20, 20, 120, 0.3);
}
.media {
  display: flex;
  align-items: center;
}
.media_img_wrap {
  flex-shrink: 0;
  /* width: 70px; */
  overflow: hidden;
  /* margin-right: 15px; */
}
.media_img {
  width: 50px;
  height: 50px;
  overflow: hidden;
  object-fit: cover;
  /* border: 3px solid var(--color-gray-light5); */
}
.media_body {
  flex-grow: 1;
}
.card_title {
  font-weight: var(--fw-b);
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.5;
}

.card_subtitle {
  color: var(--color-gray7);
  font-size: 13px;
  line-height: 1.5;
  font-weight: var(--fw-n);
}

.dark_theme .leaderboard_member_card .card_subtitle {
  color: var(--color-gray-light3);
}
