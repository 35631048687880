/** Home Guest Navigation **/
.guest_header {
  background-color: transparent;
  padding: 10px 0;
  position: relative;
  z-index: 1;
}
.guest_header .brand_logo {
  position: relative;
  width: 200px;
  text-align: center;
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
/**************/
