.member_list_wrap {
  max-height: 200px;
  overflow-y: scroll;
  /* border: 1px solid var(--color-gray-light4); */
  background-color: var(--color-gray-light7);
}
.member_list_wrap::-webkit-scrollbar {
  display: none;
  width: 4px;
  background-color: transparent;
}

.member_list_wrap:hover::-webkit-scrollbar {
  display: block;
}

.member_list_wrap::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: 1px 8px 26px rgba(247, 176, 18, 0.6),
    -1px -8px 26px rgba(247, 176, 18, 0.6);
  box-shadow: 1px 8px 26px rgba(247, 176, 18, 0.6),
    -1px -8px 26px rgba(247, 176, 18, 0.6);
  background-color: var(--color-primary-light);
}

.member_list_wrap::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-primary);
}

.member_list_wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0),
    inset -1px -1px 1px rgba(0, 0, 0, 0);
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0),
    inset -1px -1px 1px rgba(0, 0, 0, 0);
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.01);
}

.member_list_wrap .member_list_card {
  /* margin-left: -10px; */
  /* margin-right: -10px; */
  border-radius: 0;
  background-color: transparent;
}

.member_list_card:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-light4) !important;
}

.member_list_card .card-header {
  padding: 10px 0;
  background-color: transparent;
}

.member_list_card .media {
  display: flex;
  align-items: center;
}

.member_list_card .media_icon_wrap {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--color-golden-light);
  position: relative;
  text-align: center;
}
/* .member_list_card .media_icon_wrap svg {
    font-size: 24px;
    color: var(--color-gray-light7);
  } */

.member_list_card .media_body {
  flex-grow: 1;
}

.dark_theme #group_details .bg_lightgray {
  background-color: var(--color-gray3);
}
.dark_theme #group_details .bg_primary_light {
  color: var(--color-gray3);
}
.dark_theme .member_list_wrap {
  background-color: var(--color-gray3);
}
