.inner_banner {
  background-color: transparent;
  color: var(--color-white);
  min-height: 450px;
  margin-top: -90px;
  padding-top: 100px;
}
.inner_banner.about_banner {
  background-position: bottom center !important;
}
.inner_banner.cratead_banner {
  background-position: top center !important;
  background-size: contain !important;
  min-height: 600px;
}
.inner_banner.cratead_banner .card.form_style {
  bottom: 0;
}

.inner_banner .inner_banner_text h1 {
  font-size: 35px;
  font-family: "Helvetica Neue";
  font-weight: var(--fw-el);
  line-height: 1.2;
}
.inner_banner .inner_banner_text h1 strong {
  font-size: 50px;
  font-weight: var(--fw-b);
  display: block;
}
