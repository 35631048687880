.carousel-control-next,
.carousel-control-prev {
  width: auto;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  width: 36px;
  height: 36px;
  font-size: 26px;
  background: var(--color-gray-light3);
  position: absolute;
  top: 50%;
  line-height: 1;
  border-radius: 50%;
}
.owl-carousel .owl-nav button.owl-prev {
  left: -15px;
}
.owl-carousel .owl-nav button.owl-next {
  right: -15px;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background: var(--color-gray-light2);
  color: #fff;
  text-decoration: none;
}
.owl-carousel .owl-nav button.owl-prev.disabled,
.owl-carousel .owl-nav button.owl-next.disabled {
  display: none;
}
.owl-carousel .owl-nav button.owl-prev > span,
.owl-carousel .owl-nav button.owl-next > span {
  /* background-color: pink; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3px;
}
