/* .ratings ul li {
	display: inline-block;
	font-size: 13px;
	color: var(--color-gray9);
}
.ratings ul li:not(:last-child) {
	margin-right: 3px;
}
.ratings ul li:global(.active) {
	color: var(--color-golden);
}

.ratings:global(.fill) ul li {
	background-color: var(--color-gray-light3);
	color: var(--color-white);
	padding: 1px 4px;
	border-radius: 2px;
}
.ratings:global(.fill) ul li:global(.active) {
	color: var(--color-golden);
	background-color: var(--color-primary-light);
} */

.ratings {
  font-size: 13px;
  color: var(--color-gray9);
}
.ratings-list {
  border: 0;
}
.ratings-list-group {
  list-style: none;
}

.ratings-list-item {
  border: 0;
  display: inline-block;
  line-height: 1;
  border-radius: 3px !important;
}
.ratings-list-item:not(:last-child) {
  margin-right: 3px;
}
.ratings-list-item.active {
  color: var(--color-golden);
}

.ratings.fill .ratings-list-item {
  background-color: var(--color-gray-light1);
  color: var(--color-white);
  padding: 4px;
}
/* .ratings.fill .ratings-list-item.active {
  color: var(--color-golden-darker);
  background-color: var(--color-golden-lighter);
} */
.ratings.fill .ratings-list-item.active {
  color: var(--color-golden-lighter);
  background-color: var(--color-secondary-light);
}
.ratings.fill .ratings-list-item svg {
  line-height: 1;
}
