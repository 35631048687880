.custom-rating {
  position: relative;
  line-height: 1;
}
.custom-rating-tooltip {
  position: absolute;
  margin-left: 10px !important;
  /* padding: 2px 8px !important; */
  background-color: rgba(0, 0, 0, 0.7) !important;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  font-size: 14px !important;
  letter-spacing: 1px;
  -webkit-transition: all 0.28s ease-in-out;
  -moz-transition: all 0.28s ease-in-out;
  transition: all 0.28s ease-in-out;
}
.custom-rating-tooltip.tooltip-large {
  padding: 6px 18px !important;
  font-size: 15px !important;
}
.custom-rating-tooltip.tooltip-small {
  padding: 2px 8px !important;
  font-size: 13px !important;
}
.custom-rating-tooltip.show-hover {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.98) translateY(2px);
  -moz-transform: scale(0.98) translateY(2px);
  transform: scale(0.98) translateY(2px);
}
.custom-rating:hover + .custom-rating-tooltip {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1) translateY(0);
  -moz-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}
