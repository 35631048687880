/** Filter chips **/
.filters_applied {
	display: block;
}
.filters_applied > span {
	margin-right: 10px;
}
:global(.alert) {
	padding: 5px;
	font-size: 13px;
	margin-bottom: 2px;
	padding-right: 25px;
	border: 1px solid var(--color-gray-light4);
}
:global(.alert) + :global(.alert) {
	margin-left: 5px;
}
:global(.alert-dismissible) :global(.btn-close) {
	padding: 5px;
	font-size: 12px;
	bottom: 0;
	margin: auto;
	background-size: 9px;
}
/*****************/
