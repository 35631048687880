.video_card .video_wrapper {
  position: relative;
  height: 360px;
  border: 3px solid var(--color-gray1);
  box-shadow: 0 1px 10px rgb(0 0 0 / 50%);
  background-color: rgba(0, 0, 0, 0.9);
}

.video_card .video_wrapper iframe {
  width: 100%;
  height: 100%;
  -webkit-object-fit: contain;
  object-fit: contain;
  -webkit-object-position: top;
  object-position: top;
  border: 0px;
}
.video_card:hover {
  cursor: pointer;
}
.video_card .heading-icon {
  transition: 0.25s ease;
  color: var(--color-youtube)
}
.video_card:hover .heading-icon {
  transform: scale(1.2);
}

@media screen and (max-width: 576px) {
  .video_card .heading {
    font-size: 14px;
  }
}