.invitation_card {
  padding: 0;
  /* border-radius: 4px; */
  position: relative;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.invitation_card .consult_icon {
  position: absolute;
  right: 15px;
  top: 15px;
  /* background-color: var(--color-golden-lighter); */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
  border-radius: 50%;
}
.invitation_card .consult_icon img {
  height: 30px;
  filter: drop-shadow(0 0 0 black);
}
.dark_theme .invitation_card .consult_icon img {
  filter: brightness(0) invert(1);
}

.invitation_card .card-header,
.invitation_card_header {
  padding: 20px;
  /* background-color: var(--color-gray-light7); */
  background-color: transparent;
  border: 0px;
}
.invitation_card .card-body,
.invitation_card_body {
  padding: 20px;
}

/* .invitation_card .media {
  display: flex;
  align-items: center;
}

.invitation_card .media_img_wrap {
  flex-shrink: 0;
}
.invitation_card .media_img {
  width: 57px;
  height: 57px;
  overflow: hidden;
  object-fit: cover;
  border: 3px solid var(--color-gray-light5);
} */
.invitation_card .media_body {
  flex-grow: 1;
}

.invitation_card .card_title {
  font-weight: var(--fw-sb);
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 1.5;
}

.invitation_card .card_subtitle {
  color: var(--color-gray6);
  font-size: 13px;
  line-height: 1.5;
  font-weight: var(--fw-n);
}
