.xp_card {
  position: relative;
  overflow: hidden;
}
.xp_card::before {
  content: "earned";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--color-secondary-light);
  color: #fff;
  padding: 5px 6px 5px 8px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: var(--fw-sb);
  line-height: 1.2;
  border-bottom-right-radius: 15px;
  /* border-bottom: 2px solid var(--color-gray2); */
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  /* box-shadow: 0 2px 12px -3px rgb(14 19 40);*/
  letter-spacing: 0.025rem;
}
.xp_card:hover {
  -webkit-transform: scale(0.99) translateY(-2px);
  -moz-transform: scale(0.99) translateY(-2px);
  transform: scale(0.99) translateY(-2px);
  -webkit-box-shadow: 0 5px 15px -10px rgba(20, 20, 120, 0.3);
  -moz-box-shadow: 0 5px 15px -10px rgba(20, 20, 120, 0.3);
  box-shadow: 0 5px 15px -10px rgba(20, 20, 120, 0.3);
}
