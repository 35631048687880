.settings_title {
  color: var(--color-gray8);
}

.publish_settings_card {
  /* margin-left: -10px; */
  /* margin-right: -10px; */
  border-radius: 0;
  background-color: transparent;
}

.publish_settings_card .card-header {
  padding: 10px 0;
  background-color: transparent;
}

.publish_settings_card .media {
  display: flex;
  align-items: center;
}

.publish_settings_card .media_heading {
  flex-shrink: 0;
  width: 200px;
  text-align: left;
}
/* .member_list_card .media_icon_wrap svg {
    font-size: 24px;
    color: var(--color-gray-light7);
  } */

.publish_settings_card .media_body {
  flex-grow: 1;
}
