.theme_modal :global(.modal-content) {
  border: 0px;
}

.theme_modal :global(.btn-close) {
  position: relative;
  font-size: 12px;
  line-height: 0.6;
  background-image: none;
  background-color: transparent;
  opacity: 1;
  color: var(--color-gray-light4);
  outline: none;
  padding: 0;
  width: 40px;
  height: 40px;
  border: 2px solid var(--color-gray-light4);
  border-radius: 50%;
  font-weight: 300;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.theme_modal :global(.modal-header) :global(.btn-close):before,
.theme_modal :global(.modal-header) :global(.btn-close):after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  content: " ";
  height: 21px;
  width: 2px;
  background-color: var(--color-gray-light4);
}

.theme_modal :global(.modal-header) :global(.btn-close):before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
.theme_modal :global(.modal-header) :global(.btn-close):after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.theme_modal :global(.modal-header) :global(.btn-close):hover {
  background-color: var(--color-primary-dark1);
  opacity: 1;
  border-color: var(--color-primary-dark1);
}

.theme_modal :global(.modal-header) :global(.btn-close):focus {
  outline: none;
}

.theme_modal :global(.modal-header) {
  border-bottom-color: #f2f2f2;
  background: linear-gradient(
    45deg,
    var(--color-primary),
    var(--color-secondary)
  );
  color: var(--color-white);
}
.theme_modal :global(.modal-title) {
  font-size: 20px;
  font-weight: var(--fw-sb);
}
.theme_modal :global(.modal-header),
.theme_modal :global(.modal-body) {
  padding: 20px;
}

.theme_modal :global(.modal-body) p {
  font-size: 16px;
  font-weight: var(--fw-l);
}

.profile_img {
  width: 80%;
}
.member_designation {
  font-size: 16px;
  font-weight: var(--fw-n);
  margin-bottom: 10px;
}

/* .theme_modal.profile_modal .modal-body .profile_img {
	width: 80%;
}

.theme_modal.profile_modal .modal-body .member_designation {
	font-size: 1.6rem;
	font-weight: var(--fw-n);
	margin-bottom: 1rem;
} */
