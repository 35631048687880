.dictionary_card {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.dictionary_card .bg_letter {
  transform: rotate(-20deg) scale(2);
  color: var(--color-gray-light8);
  font-size: 6rem;
  font-weight: var(--fw-eb);
  top: 1rem;
  left: 1rem;
  z-index: -1;
}

.dictionary_card .card-header {
  background-color: transparent;
}
.dictionary_card .card-header .card-title {
  font-size: 18px !important;
}

/* .dark_theme .dictionary_card :global(.card-header) {
  background-color: var(--color-gray3);
} */

.votes_wrap {
  text-align: right;
}
.agree_cont {
  /* max-width: 180px; */
  display: block;
  margin-top: 5px;
}
.dark_theme .dictionary_card .text_secondary_dark {
  color: var(--color-primary-light1);
}
.dark_theme .dictionary_card .text_secondary {
  color: var(--color-primary-lighter);
}
