.subscription_plan_card {
  cursor: pointer;
  backface-visibility: hidden;
}
.subscription_plan_card:hover {
  transform: scale(1.02) translateY(-2px);
  background: var(--color-gray-light7);
}

.dark_theme .subscription_plan_card .text_secondary_dark {
  color: var(--color-primary);
}
.dark_theme .subscription_plan_card .text_gray6 {
  color: var(--color-gray9);
}
