.placeholder_card .card-header {
  background-color: transparent;
}
.placeholder_card .placeholder_profile_img {
  width: 60px;
  height: 60px;
  border: 3px solid #fff;
  box-shadow: 0 2px 8px rgba(120, 120, 120, 0.3);
}

.placeholder_card.placeholder_friend_card .placeholder_profile_img {
  width: 80px;
  height: 80px;
}
