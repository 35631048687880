.dashboard_header {
  position: relative;
  min-height: 200px;
  /* background: linear-gradient(
    to right,
    var(--color-secondary),
    var(--color-primary-light)
  ); */
  /* z-index: 1; */
}
.dashboard_header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  min-height: 200px;
  background: linear-gradient(
    to right,
    var(--color-secondary),
    var(--color-primary-light)
  );
  /* z-index: 2; */
}
/* .dark_theme .dashboard_header {
  background: var(--color-gray3a);
} */

.theme_navbar {
  background-color: transparent;
  padding: 20px 0;
  -webkit-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.theme_navbar::before {
  content: "";
  background: linear-gradient(
    to right,
    var(--color-secondary),
    var(--color-primary-light)
  );
  -webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
  height: 0%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
  -webkit-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}
/* .dark_theme .theme_navbar::before {
  background: var(--color-gray3a);
} */

.theme_navbar.fixed {
  padding: 6px 0;
}

.theme_navbar.fixed.active::before {
  height: 100%;
}

.theme_navbar .form_style .form-group {
  margin-bottom: 0;
}

.theme_navbar .navbar-brand {
  position: relative;
  width: 200px;
  text-align: center;
}

.theme_navbar .navbar-brand img {
  height: 60px;
  -webkit-transition: 0.6s ease-in-out;
  -o-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
}

.theme_navbar.fixed .navbar-brand img {
  height: 60px;
}

.theme_navbar .dashboard_navigation .nav_link.btn_theme_trigger {
  overflow: hidden;
  position: relative;
}

.theme_navbar .dashboard_navigation .nav_link.btn_theme_trigger i.fa {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 19px;
  transition: 0.3s ease;
}
/* .theme_navbar .dashboard_navigation .nav_link.btn_theme_trigger i.fa-sun-o {
  opacity: 0;
  top: 20px;
}

.dark_theme
  .theme_navbar
  .navbar-nav
  .nav-item
  .nav-link.btn_theme_trigger
  i.fa-sun-o {
  opacity: 1;
  top: 10px;
}
.dark_theme
  .theme_navbar
  .navbar-nav
  .nav-item
  .nav-link.btn_theme_trigger
  i.fa-moon-o {
  opacity: 0;
  top: -20px;
} */

.theme_navbar.fixed .navbar-nav .nav-item .dropdown-menu:before {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f2f2f2;
  display: inline-block;
  position: absolute;
  top: -10px;
  left: 20px;
}
.theme_navbar.fixed .navbar-nav .nav-item .dropdown-menu .dropdown-item.active,
.theme_navbar.fixed .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover {
  background-color: #e0e0e0;
}

.theme_navbar .dashboard_navigation .nav_link:not(:last-child) {
  margin-right: 5px;
}

.theme_navbar .navbar-nav .nav-item:not(:last-child) {
  margin-right: 5px;
}

.theme_navbar .navbar-nav .nav-item.active > .nav-link {
  color: var(--color-primary);
}

.theme_navbar .dashboard_navigation .nav_link,
.theme_navbar .dashboard_navigation .nav_link.dropdown {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color-white);
  border: 2px solid var(--color-white);
  background-color: transparent;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.theme_navbar .dashboard_navigation .nav_link.dropdown .btn {
  background-color: transparent;
  border: 1px solid transparent;
}
.theme_navbar .dashboard_navigation .nav_link:hover {
  color: var(--color-primary);
  background-color: var(--color-white);
  border-color: var(--color-white);
}
.theme_navbar .dashboard_navigation .nav_link.dropdown .btn:hover,
.theme_navbar .dashboard_navigation .nav_link.dropdown.show .btn {
  color: var(--color-primary);
}

.theme_navbar
  .dashboard_navigation
  .nav_link.dropdown
  .btn.dropdown-toggle::after {
  display: none;
}

.theme_navbar .navbar-nav .nav-item.show .nav-link {
  color: var(--color-primary);
  background-color: var(--color-white);
  border-color: var(--color-white);
}

.theme_navbar .dashboard_navigation .dropdown .dropdown-menu {
  min-width: 200px;
  background-color: #fff;
  padding: 0;
  display: block;
  top: 150%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  border-radius: 5px;
  overflow: hidden;
  overflow-y: visible;
  border: 0;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
}

.theme_navbar .dashboard_navigation .dropdown .dropdown-menu:before {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--color-white);
  display: inline-block;
  position: absolute;
  top: -10px;
  left: 20px;
}
.theme_navbar .dashboard_navigation .dropdown .dropdown-menu .dropdown-item {
  white-space: normal;
  text-transform: none;
  padding: 15px;
  border-bottom: 1px solid var(--color-gray-light5);
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.theme_navbar .dashboard_navigation .dropdown .dropdown-menu.show {
  top: 120%;
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 991px) {
  .theme_navbar .navbar-brand img,
  .theme_navbar.fixed .navbar-brand img {
    height: 50px;
  }
}

/* .theme_navbar .logo_box .btn_theme_trigger {
  display: none !important;
  overflow: hidden;
  position: absolute;
  right: 65px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color-white);
  border: 1px solid var(--color-white);
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.theme_navbar .logo_box .btn_theme_trigger i.fa {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 19px;
  transition: 0.3s ease;
}
.theme_navbar .logo_box .btn_theme_trigger i.fa-sun-o {
  opacity: 0;
  top: 20px;
}
.dark_theme .theme_navbar .logo_box .btn_theme_trigger i.fa-sun-o {
  opacity: 1;
  top: 10px;
}
.dark_theme .theme_navbar .logo_box .btn_theme_trigger i.fa-moon-o {
  opacity: 0;
  top: -20px;
} */
