.social_links li {
  border: 0;
  padding: 3px 5px;
}
.social_links li a {
  display: block;
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 32px;
  font-size: 16px !important;
  background-color: var(--color-primary);
  color: var(--color-white);
  border-radius: 50%;
  -webkit-box-shadow: 0 0.1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.1px 5px rgba(0, 0, 0, 0.2);
}

.social_links li a:hover {
  background-color: var(--color-primary-dark1);
  color: var(--color-white);
  -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
}
.social_links.theme_white li a {
  border-color: #666;
  color: #666;
  background-color: transparent;
}
.social_links.theme_white li a:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}
