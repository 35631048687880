.tab_slider .tab_options {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.tab_slider .tab_options .tab_options_list {
  text-align: center;
  color: var(--color-gray6);
  margin-top: 0px;
  position: relative;
  background: transparent;
  /* border: 1px solid #666; */
  /*float: none;*/
  /*  margin-right: 7px;*/
  margin-right: 0;
  padding: 10px 8px 35px;
  cursor: pointer;
  font-size: 14px;
  /*width: 183px;*/
  min-height: 170px;
  /* text-transform: uppercase; */
  -webkit-transition: var(--transition-normal);
  -o-transition: var(--transition-normal);
  transition: var(--transition-normal);
  outline: none;
}
.tab_slider .tab_options .tab_options_list:before {
  content: "";
  position: absolute;
  background-color: var(--color-gray-light4);
  width: 1px;
  height: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
}
.tab_slider .tab_options .tab_options_list .tab_option_img {
  width: 120px;
  height: 120px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 50%;
  background-color: var(--color-white);
}
.tab_slider .tab_options .tab_options_list span {
  margin-top: 20px;
  display: block;
  letter-spacing: 1px;
  opacity: 0;
  color: var(--color-primary);
}
.tab_slider .tab_options .tab_options_list img {
  margin: auto;
  height: 50px;
}

.tab_options .slick-track .slick-current .tab_options_list .tab_option_img,
.tab_options .slick-track .tab_options_list:hover .tab_option_img {
  background: linear-gradient(
    120deg,
    var(--color-primary-light1),
    var(--color-primary-dark1)
  );
  outline: none;
}
.tab_options .slick-track .slick-current .tab_options_list:before {
  opacity: 1;
}
.tab_options .slick-track .tab_options_list:hover span {
  opacity: 1;
}

.tab_options .slick-track .tab_options_list:hover img,
.tab_options .slick-track .slick-current .tab_options_list img {
  filter: brightness(0) invert(1);
}
.tab_options .slick-track .slick-current .tab_options_list span {
  opacity: 1;
}
.tab_options .slick-track .slick-current .tab_options_list img.whiteimg {
  display: none;
}
.tab_options .slick-track .slick-current .tab_options_list img.hovimg {
  display: block;
}

.tab_content_slider_wrap {
  border: 1px solid var(--color-gray-light4);
  padding: 30px;
  border-radius: 10px;
}
.tab_content_slider_wrap .btn_theme {
  margin: 0 auto -80px;
  background-color: var(--color-white);
}
.tab_content_item h3 {
  font-weight: var(--fw-el);
  line-height: 1.2;
  color: var(--color-gray6);
  font-size: 36px;
}
/** Styles for About Page **/
.features_wrap .tab_slider .tab_options {
  margin-top: -80px;
}
.features_wrap .tab_content_slider_wrap {
  background-color: var(--color-white);
}
.features_wrap .tab_slider .tab_options .tab_options_list span {
  color: var(--color-white);
}
/*************/

@media screen and (max-width: 991px) {
  .tab_content_item h3 {
    font-size: 22px;
  }
}

@media screen and (max-width: 767px) {
  .tab_slider .tab_options .tab_options_list .tab_option_img {
    width: 100px;
    height: 100px;
  }
  .tab_slider .tab_options .tab_options_list img {
    margin: auto;
    height: 40px;
  }
}

@media screen and (max-width: 480px) {
  .tab_slider .tab_options .tab_options_list .tab_option_img {
    width: 80px;
    height: 80px;
  }
  .tab_slider .tab_options .tab_options_list img {
    margin: auto;
    height: 30px;
  }
}
