.custom_accordion_wrap {
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
}

.dark_theme .custom_accordion_wrap {
  background-color: var(--color-gray25);
}

.btn_accordion {
  padding: 0 20px 0 20px;
  height: 60px;
  font-size: 16px;
  font-weight: var(--fw-sb);
  text-align: left;
  color: var(--color-primary-light);
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
}
.btn_accordion:hover,
.btn_accordion:focus,
.btn_accordion:active,
.btn_accordion:active:focus {
  background-color: transparent;
  color: var(--color-primary-dark1);
  box-shadow: none;
  outline: 2px solid transparent;
}

.btn_accordion::after {
  content: "\f067";
  font-family: FontAwesome;
  background-image: none;
  border: 3px solid var(--color-gray-light3);
  border-radius: 50%;
  color: var(--color-gray-light3);
  width: 26px;
  height: 26px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
.btn_accordion[aria-expanded="true"]::after {
  content: "\f068";
}
.btn_accordion img {
  margin-right: 10px;
}
.dark_theme .btn_accordion {
  background-color: var(--color-gray2);
}

@media screen and (max-width: 640px) {
  .btn_accordion {
    padding: 0 15px 0 15px;
  }
}