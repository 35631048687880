/** Custom Progress **/
.custom_progress :global(.progress) {
	width: 100%;
}
.custom_progress :global(.progress.rounded_progress) {
	border-radius: 20px;
}
.custom_progress :global(.progress.small_progress) {
	height: 8px;
	background-color: var(--color-gray-light5);
	box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.2);
}
.custom_progress :global(.progress_value) {
	font-size: 12px;
	color: var(--color-success);
	text-transform: uppercase;
}
.custom_progress :global(.progress) :global(.progress-bar) {
	background-color: var(--color-success);
}

/********************/
