.field-error {
  color: #ff0000;
  font-size: 0px !important;
  /* margin: 0.5em 0; */
  margin: 0;
  height: 0;
  padding-left: 1rem;
  -webkit-transition: 0.21s ease-in-out;
  -moz-transition: 0.21s ease-in-out;
  transition: 0.21s ease-in-out;
}
.field-error span {
  background: transparent;
  /* padding: 3px 6px; */
  /* line-height: 1; */
  color: #fff;
  /* display: inline-block; */
  border-radius: 3px;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 1px;
  opacity: 0;
  -webkit-transition: 0.15s ease;
  -moz-transition: 0.15s ease;
  transition: 0.15s ease;
  /* -webkit-transform: translate(-10px, -15px);
  -moz-transform: translate(-10px, -15px);
  transform: translate(-10px, -15px); */
}
.field-error.active {
  height: 5px;
}
.field-error.active span {
  background: var(--color-danger-light);
  /* -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  transform: translate(0px, 0px); */
  opacity: 1;
  padding: 3px 8px;
}
