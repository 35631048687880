.network_friend_card .card-body {
  padding: 20px 10px;
}
.network_friend_card .icon_wrap {
  width: 80px;
  height: 80px;
  border: 2px solid var(--color-gray-light5);
}
.network_friend_card .icon_wrap img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
