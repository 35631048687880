.custom_card.forum_card {
  overflow: hidden;
  /* background-color: var(--color-primary-lighter); */
}
.custom_card.forum_card > .card-header {
  /* background-color: var(--color-primary-lighter); */
  background-color: transparent;
  padding-bottom: 5px;
}

.forum_card .media_icon_wrap {
  background-color: var(--color-primary);
}
.forum_card .media_icon_wrap svg {
  font-size: 24px;
  color: var(--color-gray-light7);
}
.forum_card .media_body {
  flex-grow: 1;
}
