.accordion_container {
  background-color: transparent;
  /* padding: 20px; */
}
.accordion_container.collapse.show {
  border-top: 1px solid var(--color-gray-light5);
}
.accordion_container > div {
  padding: 20px;
}
.dark_theme .accordion_container {
  background-color: var(--color-gray2);
}

.dark_theme .accordion_container.collapse.show {
  border-top: 1px solid var(--color-gray3a);
}

@media screen and (max-width: 640px) {
  .accordion_container > div {
    padding: 20px 15px;
  }  
}