.chart-legend-list {
  border: 0;
}
.chart-legend-list .chart-legend-list-item {
  border: 0;
  /* margin-right: 4px; */
  /* margin-bottom: 6px; */
  font-size: 14px;
  padding: 1px 5px;
  background-color: transparent;
}
.chart-legend-list-item .chart-color-dot {
  background: var(--color-gray-light2);
  width: 16px;
  height: 14px;
  border-radius: 2px;
  margin-right: 8px;
}
