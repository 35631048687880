/** Main Banner **/
.split_banner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.main_banner {
  position: relative;
  height: 100vh;
  margin-top: -90px;
  background-color: var(--color-white);
}

.split_item {
  height: 100%;
  display: flex;
}

.split_item.split_item_70 {
  justify-content: flex-end;
  width: 70%;
}
.split_item.split_item_30 {
  width: 30%;
}
/* .split_banner .split_item .card {
  max-width: 400px;
  width: 100%;
  padding: 40px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  margin-left: -100px;
  align-self: center;
} */

.split_item_content {
  width: 100%;
  max-width: 860px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.split_item_content h1 {
  font-family: "Helvetica Neue";
  color: var(--color-white);
  font-size: 70px;
  font-weight: var(--fw-sb);
  line-height: 1;
}
.split_item_content h1 span {
  display: block;
}
.split_item_content h1 .line1 {
  font-size: 110px;
}
.split_item_content h1 .line2 {
  font-size: 165px;
}

.main_banner.split_banner .split_item_content h1 {
  line-height: 1;
}

/* .main_banner.split_banner .split_item_content .btn_explore_wrap */
.btn_explore_wrap {
  position: absolute;
  /* bottom: 22rem; */
  bottom: 120px;
  z-index: 50;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  display: none;
}
/* .main_banner.split_banner .split_item_content .btn_explore_wrap .btn_theme */
.btn_explore_wrap .btn_theme {
  position: relative;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 300;
  color: #ccc;
  padding: 5px;
  min-width: 0;
  width: 34px;
  height: 34px;
  line-height: 30px;
  border-radius: 50%;
  border: 0;
  background-color: rgba(4, 40, 108, 0.6);
  /* background-color: var(--color-secondary); */
  -webkit-animation: icon_fade 1.8s linear infinite;
  -o-animation: icon_fade 1.8s linear infinite;
  animation: icon_fade 1.8s linear infinite;
}
@-webkit-keyframes icon_fade {
  0%,
  100% {
    opacity: 0.5;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    opacity: 0.95;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}

@-o-keyframes icon_fade {
  0%,
  100% {
    opacity: 0.5;
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    opacity: 0.95;
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
}

@keyframes icon_fade {
  0%,
  100% {
    opacity: 0.5;
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    opacity: 0.95;
    -webkit-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
}

.main_banner .container,
.main_banner .row {
  height: 100%;
}

.main_banner .container {
  padding-top: 120px;
}

/*************/
@media screen and (min-width: 1681px) {
  .split_item.split_item_70 {
    background-size: cover;
  }
}

@media screen and (max-width: 1440px) {
  .split_item.split_item_70 {
    background-size: cover;
  }
}
@media screen and (max-width: 1366px) {
  .split_item_content {
    max-width: 830px;
  }
}
@media screen and (max-width: 1199px) {
  .main_banner .split_item_content {
    max-width: 710px;
  }
  .main_banner.split_banner .split_item_content h1 {
    font-size: 40px;
  }
  .main_banner .split_item_content h1 .line1 {
    font-size: 80px;
  }
  .main_banner .split_item_content h1 .line2 {
    font-size: 125px;
  }
}
@media screen and (max-width: 1024px) {
  .main_banner .split_item_content {
    max-width: 640px;
  }
  .main_banner.split_banner .split_item .card {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 991px) {
  /* .main_banner.split_banner {
    background-image: url(`${process.env.PUBLIC_URL}/img/main-banner-bg.jpg`)
      no-repeat center;
  } */
  .main_banner.split_banner {
    flex-wrap: wrap;
    height: auto;
    padding-bottom: 60px;
  }
  .main_banner .split_item_content {
    max-width: 720px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  .main_banner.split_banner .split_item.split_item_70,
  .main_banner.split_banner .split_item.split_item_30 {
    width: 100%;
    height: auto;
  }
  .main_banner.split_banner .split_item.split_item_70 {
    justify-content: center;
    padding: 120px 0 180px;
    /* Changed (background-position: bottom -100px center) : bottom value from -100px to 0 to adjust the background image from top */
    background-position: bottom 0px center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .main_banner.split_banner .split_item.split_item_30 {
    padding-bottom: 30px;
  }

  .main_banner.split_banner .split_item .card {
    margin-left: auto;
    margin-right: auto;
    margin-top: -170px;
    max-width: 720px;
  }

  .main_banner.split_banner .split_item_content h1 {
    font-size: 32px;
  }
  .main_banner .split_item_content h1 .line1 {
    font-size: 64px;
  }
  .main_banner .split_item_content h1 .line2 {
    font-size: 100px;
  }
}
@media screen and (max-width: 767px) {
  .main_banner .split_item_content {
    max-width: 540px;
  }
  .main_banner.split_banner .split_item.split_item_70 {
    background-position: bottom 0px center;
  }
  .main_banner.split_banner .split_item_content h1 {
    font-size: 24px;
  }
  .main_banner .split_item_content h1 .line1 {
    font-size: 50px;
  }
  .main_banner .split_item_content h1 .line2 {
    font-size: 70px;
  }
  .main_banner.split_banner .split_item .card {
    margin-top: -170px;
    max-width: 540px;
  }
}

@media screen and (max-width: 576px) {
  .main_banner .split_item_content {
    max-width: 100%;
  }
  .main_banner.split_banner .split_item.split_item_30 {
    padding-bottom: 30px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  .main_banner.split_banner .split_item .card {
    margin-top: -170px;
    max-width: 100%;
  }
}

/******** OPENAI INPUT FIELD CSS ********/
.response-container {
  margin-top: 35px;
  position: relative;
}

.interpret-search-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px; /* Spacing after the H1 text */
}

.interpret-search-bar input[type="text"] {
  width: 100%; /* Makes the search bar wider */
  max-width: 600px; /* You can adjust max-width as needed */
  border: 1px solid #ccc; /* Simple border, you can change color/style */
  padding: 15px; /* Adjust padding to match your design */
  border-radius: 15px; /* Rounded corners */
  font-size: 16px; /* Adjust font size as needed */
  margin-bottom: 20px; /* Adds some space below the search bar */
}

.interpret-search-bar button {
  padding: 17px 30px; /* Adjust to match search bar height */
  border-radius: 15px; /* Rounds the corners */
  border: none; /* Removes the border */
  background-color: #3a99ff; /* Example blue color, adjust as needed */
  color: white; /* Text color for the button */
  cursor: pointer; /* Changes cursor to pointer on hover */
  font-size: 16px; /* Adjust font size as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for depth */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

/* You can add hover effects for the button */
.interpret-search-bar button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Tooltip style */
.interpret-response-tooltip {
  position: absolute; /* Position it absolute to the search bar */
  z-index: 100;
  top: 100%; /* Place it right below the search bar */
  left: 50%;
  transform: translateX(-50%); /* Center it */
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
  margin-top: 25px; /* Slight space between the search bar and tooltip */
  width: 100%; /* Tooltip width */
  max-width: 600px; /* Max width to align with the search bar */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: none; /* Hide by default */
  font-size: 14px; /* Font size */
  line-height: 1.6; /* Line height for readability */
  color: rgb(105, 105, 105); /* Font color */
}

/* Invisible layer to prevent highlighting of the arrow */
.interpret-response-tooltip::before {
  content: "";
  position: absolute;
  top: -10px; /* Adjust if necessary, should match the arrow's positioning */
  left: 50%;
  transform: translateX(-50%);
  width: 20px; /* Same as the arrow width */
  height: 20px; /* Same as the arrow height */
  background-color: transparent;
  z-index: -1; /* Places this layer below the content */
}

/* Tooltip arrow */
.interpret-response-tooltip::after {
  content: "";
  position: absolute;
  top: -10px; /* Position the arrow at the top of the tooltip */
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 20px;
  height: 20px;
  background-color: #f9f9f9;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  z-index: -2; /* Ensures the arrow is below the invisible layer */
}

/* Show the tooltip when there's a response */
.interpret-search-bar.active + .interpret-response-tooltip,
.interpret-response-tooltip.active {
  /* This line is updated to correctly show the tooltip */
  display: block; /* Show the tooltip */
}

/* Add disabled styling for the input field */
.interpret-search-bar input.disabled {
  background-color: #f0f0f0; /* Lighter color to indicate it's not active */
  color: #ccc; /* Grey out the text to indicate it's disabled */
  cursor: not-allowed; /* Show a not-allowed cursor icon */
}

/* Update the button disabled style */
.interpret-search-bar button.disabled {
  background-color: #3a99ff; /* Keep the gray color */
  color: #fff; /* Optionally, make the text color lighter to contrast less */
  cursor: not-allowed; /* Continue using the not-allowed cursor */
  /* pointer-events: none; is not necessary due to the disabled attribute */
  opacity: 1; /* Make the button appear faded out */
}

/* Loading animation for the Chat GPT functionality */
.center {
  height: 40vh;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.wave {
  width: 8px;
  height: 70px;
  /* background: linear-gradient(45deg, rgb(0, 166, 255), rgb(191, 229, 255)); */
  margin: 8px;
  /* animation: wave 1s linear infinite; */
  /* border-radius: 50px; */
}
/* .wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
} */

/* @keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
} */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Adjust the Tooltip: Position it back to relative to the search bar  */
@media screen and (max-width: 991px) {
  /* Tooltip style */
  .interpret-response-tooltip {
    position: relative; /* Position it relative to the search bar */
  }
}

/* Adjustments for the button to appear next to the search bar on larger screens */
@media screen and (min-width: 768px) {
  .interpret-search-bar {
    flex-direction: row;
    justify-content: center;
  }

  .interpret-search-bar input[type="text"] {
    border-radius: 25px 0 0 25px; /* Adjusts the border radius for the left side */
    margin-bottom: 0; /* Resets the margin below the search bar */
  }

  .interpret-search-bar button {
    border-radius: 0 25px 25px 0; /* Adjusts the border radius for the right side */
    margin-left: -1px; /* Overlaps the button with the search bar to appear seamless */
  }
}

/* Media query for very small screens to ensure responsiveness */
@media screen and (max-width: 480px) {
  .interpret-search-bar input[type="text"] {
    width: 90%; /* Makes the search bar take more width on smaller screens */
  }
}
