.section_subheading {
  font-size: 26px;
  /* font-weight: var(--fw-l); */
}
.subheading_large {
  font-size: 28px;
  color: var(--color-gray3);
}
.subheading_medium {
  font-size: 20px;
  color: var(--color-gray3);
}
.subheading_small {
  font-size: 16px;
  color: var(--color-gray3);
}

@media screen and (max-width: 767px) {
  .section_subheading {
    font-size: 20px;
  }
}
