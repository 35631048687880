.experience_card {
  padding: 0;
  border-radius: 15px;
  position: relative;
  /* overflow: hidden; */
  transition: 0.3s ease-in-out;
}

.experience_card.dreams {
  border-top: 3px solid var(--color-dreams) !important;
}
.experience_card.events {
  border-top: 3px solid var(--color-events) !important;
}
.experience_card.thoughts {
  border-top: 3px solid var(--color-thoughts) !important;
}
.experience_card.outofbody {
  border-top: 3px solid var(--color-outofbody) !important;
}

.experience_card_header {
  padding: 15px 15px 5px;
  /* background-color: var(--color-gray-light7); */
  background-color: transparent;
  border: 0px;
}

/* .dark_theme
  .experience_card:global(.main_card)
  :global(.experience_card_header) {
  background-color: var(--color-gray2) !important;
} */

.experience_card_body {
  padding: 15px;
}

.experience_card .media {
  display: flex;
  align-items: center;
}
.experience_card .media_icon_wrap {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--color-gray-light1);
  border: 3px solid #fff;
  box-shadow: 0 2px 8px rgba(120, 120, 120, 0.3);
  position: relative;
  text-align: center;
}
.experience_card.dreams .media_icon_wrap,
.experience_card.dreams .experience_card.dreams .media_icon_wrap,
.experience_card.events .experience_card.dreams .media_icon_wrap,
.experience_card.thoughts .experience_card.dreams .media_icon_wrap,
.experience_card.outofbody .experience_card.dreams .media_icon_wrap {
  background-color: var(--color-dreams);
}
.experience_card.events .media_icon_wrap,
.experience_card.events .experience_card.events .media_icon_wrap,
.experience_card.dreams .experience_card.events .media_icon_wrap,
.experience_card.thoughts .experience_card.events .media_icon_wrap,
.experience_card.outofbody .experience_card.events .media_icon_wrap {
  background-color: var(--color-events);
}
.experience_card.thoughts .media_icon_wrap,
.experience_card.thoughts .experience_card.thoughts .media_icon_wrap,
.experience_card.dreams .experience_card.thoughts .media_icon_wrap,
.experience_card.events .experience_card.thoughts .media_icon_wrap,
.experience_card.outofbody .experience_card.thoughts .media_icon_wrap {
  background-color: var(--color-thoughts);
}
.experience_card.outofbody .media_icon_wrap,
.experience_card.outofbody .experience_card.outofbody .media_icon_wrap,
.experience_card.dreams .experience_card.outofbody .media_icon_wrap,
.experience_card.events .experience_card.outofbody .media_icon_wrap,
.experience_card.thoughts .experience_card.outofbody .media_icon_wrap {
  background-color: var(--color-outofbody);
}
.experience_card .media_icon {
  width: 32px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.experience_card .media_img_wrap {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--color-gray-light1);
  border: 3px solid #fff;
  box-shadow: 0 2px 8px rgba(120, 120, 120, 0.3);
  position: relative;
  text-align: center;
}
.experience_card .media_img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  /* border: 3px solid var(--color-gray-light5); */
}
.experience_card .media_body {
  flex-grow: 1;
}

.experience_card .progressbar_wrap {
  position: absolute;
  min-width: 100px;
  top: 15px;
  right: 15px;
}

.experience_card .progressbar_wrap2 {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 15px;
  right: 15px;
}

.experience_card .card_title {
  font-weight: var(--fw-b);
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.5;
}

.experience_card .card_subtitle {
  color: var(--color-gray6);
  font-size: 13px;
  line-height: 1.5;
  font-weight: var(--fw-n);
}

.experience_card .exp_title {
  font-size: 15px;
  color: var(--color-gray4);
  font-weight: var(--fw-n);
  line-height: 1.5;
}

.dark_theme .experience_card .exp_title {
  color: var(--color-gray-light5);
}
.dark_theme .experience_card .card_subtitle {
  color: var(--color-gray-light4);
}

.dark_theme .experience_card_body .bg_lightgray {
  background-color: var(--color-gray4);
}

.dark_theme #add_association .custom_card,
.dark_theme #edit_association .custom_card {
  background-color: transparent;
}

@media screen and (max-width: 767px) {
  .experience_card .media_img_wrap,
  .experience_card .media_icon_wrap {
    flex-shrink: 0;
    width: 46px;
    height: 46px;
    border: 2px solid #fff;
    box-shadow: 0 2px 4px rgba(120, 120, 120, 0.3);
  }
  .experience_card .media_icon {
    width: 26px;
  }
}


