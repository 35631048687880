.analyze_card {
  padding: 0;
  position: relative;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.analyze_card.dreams {
  border-top: 3px solid var(--color-dreams) !important;
}
.analyze_card.events {
  border-top: 3px solid var(--color-events) !important;
}
.analyze_card.thoughts {
  border-top: 3px solid var(--color-thoughts) !important;
}
.analyze_card.outofbody {
  border-top: 3px solid var(--color-outofbody) !important;
}

.analyze_card .card-header {
  background-color: transparent;
  padding: 15px 15px 0;
}

.analyze_card_body {
  padding: 15px;
}

.analyze_card .media {
  display: flex;
  align-items: center;
}
.analyze_card .media_icon_wrap,
.analyze_card .media_img_wrap {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--color-gray-light1);
  border: 3px solid #fff;
  box-shadow: 0 2px 8px rgba(120, 120, 120, 0.3);
  position: relative;
  text-align: center;
}
.analyze_card.dreams .media_icon_wrap,
.analyze_card.dreams .analyze_card.dreams .media_icon_wrap,
.analyze_card.events .analyze_card.dreams .media_icon_wrap,
.analyze_card.thoughts .analyze_card.dreams .media_icon_wrap,
.analyze_card.outofbody .analyze_card.dreams .media_icon_wrap {
  background-color: var(--color-dreams);
}
.analyze_card.events .media_icon_wrap,
.analyze_card.events .analyze_card.events .media_icon_wrap,
.analyze_card.dreams .analyze_card.events .media_icon_wrap,
.analyze_card.thoughts .analyze_card.events .media_icon_wrap,
.analyze_card.outofbody .analyze_card.events .media_icon_wrap {
  background-color: var(--color-events);
}
.analyze_card.thoughts .media_icon_wrap,
.analyze_card.thoughts .analyze_card.thoughts .media_icon_wrap,
.analyze_card.dreams .analyze_card.thoughts .media_icon_wrap,
.analyze_card.events .analyze_card.thoughts .media_icon_wrap,
.analyze_card.outofbody .analyze_card.thoughts .media_icon_wrap {
  background-color: var(--color-thoughts);
}
.analyze_card.outofbody .media_icon_wrap,
.analyze_card.outofbody .analyze_card.outofbody .media_icon_wrap,
.analyze_card.dreams .analyze_card.outofbody .media_icon_wrap,
.analyze_card.events .analyze_card.outofbody .media_icon_wrap,
.analyze_card.thoughts .analyze_card.outofbody .media_icon_wrap {
  background-color: var(--color-outofbody);
}
.analyze_card .media_icon {
  width: 32px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.analyze_card .media_img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}
.analyze_card .media_body {
  flex-grow: 1;
}

.analyze_card .progressbar_wrap {
  position: absolute;
  min-width: 100px;
  top: 20px;
  right: 20px;
}

.analyze_card .card_title {
  font-weight: var(--fw-b);
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 1.5;
}

.analyze_card .card_subtitle {
  color: var(--color-gray6);
  font-size: 13px;
  line-height: 1.5;
  font-weight: var(--fw-n);
}

.analyze_card .exp_title {
  font-size: 14px;
  color: var(--color-gray4);
  font-weight: var(--fw-sb);
  line-height: 1.5;
  padding: 5px 0;
}

.analyze_card .exp_title .btn_theme {
  padding: 5px 0 5px 35px;
  text-align: left;
  font-size: 14px;
}
.analyze_card .exp_title .btn_theme svg {
  top: 15px;
  left: 10px;
}

.analyze_card .more-description {
  background-color: var(--color-gray-light5);
  border-radius: 5px;
}

.dark_theme .analyze_card .exp_title > .btn_theme {
  color: var(--color-gray-light5);
}
.dark_theme .analyze_card .card_subtitle {
  color: var(--color-gray-light4);
}

.dark_theme .analyze_card_body .bg_lightgray {
  background-color: var(--color-gray4);
}
.dark_theme .analyze_card .more-description {
  background-color: var(--color-gray4);
}
