.new_banner {
  height: 100vh;
  min-height: 1150px;
  overflow: hidden;
  background-color: var(--color-white);
}
/* .split_banner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
} */
/* .split_item {
  height: 100%;
  display: flex;
} */
/* .new_banner.split_banner .split_item.split_item_70 {
  width: 70%;
  background-color: teal;
} */
.new_banner.split_banner .split_item.split_item_30 {
  width: 30%;
  justify-content: flex-end;
  margin-top: 80px;
  background: linear-gradient(
    45deg,
    var(--color-primary-light2),
    var(--color-secondary)
  );
}
.new_banner.split_banner
  .split_item.split_item_30
  .new_banner_slider_indicator_wrap {
  height: 350px;
}
.new_banner.split_banner .split_item.split_item_70 {
  width: 70%;
  background-color: teal;
}
.new_banner_slider_content .slick-slider {
  width: 100%;
}

.new_banner.split_banner .split_item_content {
  max-width: 350px;
  padding-right: 30px;
}
.new_banner.split_banner .split_item_content h1 {
  font-size: 80px;
  font-weight: var(--fw-b);
  line-height: 0.9;
  color: var(--color-secondary);
}
.new_banner.split_banner .split_item_content .slick-current h1 {
  color: var(--color-white);
}
.new_banner.split_banner .split_item_content h1 .line1 {
  font-size: 34px;
  font-weight: var(--fw-b);
}

.new_banner.split_banner .split_item.split_item_70 img {
  width: 100%;
  object-fit: cover;
}

/* .split_item_content {
  height: 100%;
  display: flex;
  align-items: center;
  max-width: 350px;
  padding-right: 30px;
}
.split_item_content h1 {
  font-family: "Helvetica Neue";
  font-size: 80px;
  font-weight: var(--fw-b);
  line-height: 0.9;
  color: var(--color-secondary);
}
.split_item_content :global(.slick-current) h1 {
  color: var(--color-white);
} */
/* .new_banner.split_banner .split_item_content > div div:first-child h1 {
	color: var(--color-white);
} */
/* .split_item_content h1 :global(.line1) {
  font-size: 34px;
  font-weight: var(--fw-b);
  display: block;
} */

.split_item_70 img {
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1199px) {
  .new_banner.split_banner .split_item_content h1 {
    font-size: 54px;
  }
  .new_banner.split_banner .split_item_content h1 .line1 {
    font-size: 28px;
  }
  .new_banner.split_banner .split_item_content {
    max-width: 280px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .new_banner.split_banner .split_item_content h1 {
    font-size: 44px;
  }
  .new_banner.split_banner .split_item_content h1 .line1 {
    font-size: 22px;
  }
  .new_banner.split_banner .split_item_content {
    max-width: 250px;
  }
}

@media screen and (max-width: 991px) {
  .new_banner.split_banner .split_item_content {
    max-width: 100%;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .new_banner.split_banner
    .split_item.split_item_30
    .new_banner_slider_indicator_wrap {
    height: auto;
    width: 100%;
    padding-left: 20px;
  }
  .new_banner.split_banner {
    flex-wrap: wrap;
  }
  .new_banner.split_banner .split_item.split_item_70,
  .new_banner.split_banner .split_item.split_item_30 {
    width: 100%;
    height: auto;
  }
  .new_banner.split_banner .split_item.split_item_30 {
    margin-top: 0;
  }
  .new_banner.split_banner .split_item_content h1 {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .new_banner.split_banner .split_item_content {
    height: auto;
  }
  .new_banner.split_banner .split_item_content h1 {
    font-size: 30px;
    margin-bottom: 0;
  }
  .new_banner.split_banner .split_item_content h1 .line1 {
    font-size: 18px;
  }
  .new_banner.split_banner {
    background: linear-gradient(
      90deg,
      var(--color-primary-light2),
      var(--color-secondary-light)
    );
  }
  .new_banner.split_banner .split_item.split_item_70,
  .new_banner.split_banner .split_item.split_item_30 {
    background: none;
  }
  .new_banner.split_banner .split_item.split_item_70 {
    align-self: flex-end;
  }
}

@media screen and (max-width: 480px) {
  .new_banner.split_banner .split_item_content h1 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .new_banner.split_banner .split_item_content h1 .line1 {
    font-size: 14px;
  }
}
