.profile_card {
  padding-bottom: 30px;
  overflow: hidden;
}

.profile_card .circular-progressbar-wrap {
  width: 120px;
  height: 120px;
  position: relative;
  /* padding: 5px; */
  /* background-color: pink; */
}
.profile_card .circular-progressbar-wrap img {
  object-fit: cover;
  height: calc(100% - 18px);
  width: calc(100% - 18px);
}
.profile_card .CircularProgressbar .CircularProgressbar-path {
  stroke: url("#progress-grad") !important;
}

.profile_card .circular-progressbar-wrap .rank-status-badge {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: var(--fw-sb);
  color: var(--color-secondary-dark);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.2);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 1000000;
  transition: 0.3s ease-in-out;
}
.profile_card .circular-progressbar-wrap:hover .rank-status-badge {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.profile_card .profile_img_container {
  width: 110px;
  height: 110px;
  border: 5px solid var(--color-gray-light4);
}
.dark_theme .profile_card .profile_img_container {
  border: 5px solid var(--color-gray3a);
}
.profile_card .profile_img_container img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.dark_theme .profile_card .card-body .text_gray6 {
  color: var(--color-white);
}
.profile_card .card-body .btn_theme {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2px;
}
.profile_card .profile-stats-wrap .icon-wrap {
  width: 42px;
  height: 42px;
  background-color: var(--color-primary-lighter2);
  margin-bottom: 5px;
}
.profile_card .profile-stats-wrap .border-end {
  border-right: 1px solid var(--color-gray-light5) !important;
}
.profile_card .profile-stats-wrap .stats-value {
  line-height: 1.3;
  color: var(--color-gray6);
  font-size: 16px;
}
.profile_card .profile-stats-wrap .stats-value > span {
  color: var(--color-gray9);
}

.dark_theme .profile_card .profile-stats-wrap .stats-value {
  color: var(--color-gray-light5);
}

/* .profile_card .profile-stats-wrap img {
  height: 25px;
} */
