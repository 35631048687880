.notification_card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.notification_card a {
  color: inherit;
}

.notification_card .card-header {
  background-color: transparent;
}
.notification_card .media_icon_wrap {
  background-color: var(--color-primary-lighter);
}
.notification_card .media_icon_wrap svg {
  color: var(--color-primary-dark1);
  font-size: 24px;
  pointer-events: none;
}

.notification_card .card_title,
.notification_card .card_subtitle {
  pointer-events: none;
}

/* .notification_card .card-header,
.notification_card_header {
  padding: 20px;
  background-color: var(--color-gray-light7);
  border: 0px;
} */
/* .notification_card .card-body,
.notification_card_body {
  padding: 20px;
} */

/* .notification_card .media {
  display: flex;
  align-items: center;
}

.notification_card .media_img_wrap {
  flex-shrink: 0;
}
.notification_card .media_img {
  width: 57px;
  height: 57px;
  overflow: hidden;
  object-fit: cover;
  border: 3px solid var(--color-gray-light5);
}
.notification_card .media_body {
  flex-grow: 1;
}

.notification_card .card_title {
  font-weight: var(--fw-sb);
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 1.5;
}

.notification_card .card_subtitle {
  color: var(--color-gray6);
  font-size: 13px;
  line-height: 1.5;
  font-weight: var(--fw-n);
} */

.indicator {
  display: none;
  position: absolute;
  left: 18px;
  top: 18px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: var(--color-primary-dark1);
  border: 2px solid #fff;
  z-index: 1;
}
.notification_card.active .indicator {
  display: inline-block;
}

.notification_card .custom_tag {
  font-size: 13px;
  /* font-weight: var(--fw-n); */
}
