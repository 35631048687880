.icon_card {
  text-align: center;
  background-color: transparent;
}

.dark_theme .icon_card {
  background-color: transparent;
}

.icon_card .card-body {
  border: 1px solid transparent;
  transition: 0.25s ease;
  font-size: 13px;
  padding: 10px 5px;
  position: relative;
  border-radius: 3px;
}
.icon_card .card-body:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-primary);
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
}
.icon_card .card-body img {
  height: 40px;
  width: auto;
  margin-bottom: 10px;
  transition: 0.25s ease;
}
.icon_card .icon_title {
  color: inherit;
}
