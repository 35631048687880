.page_title {
  font-size: 18px;
  font-weight: var(--fw-sb);
}
.dark_theme .page_title img {
  filter: brightness(0) invert(0.95);
}
.section_title {
  font-size: 16px;
  font-weight: var(--fw-sb);
}

.section_title.medium_title {
  font-size: 16px;
}
.section_title.large_title {
  font-size: 18px;
}
.section_title.small_title {
  font-size: 14px;
}
