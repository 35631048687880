.message_card {
  padding: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.message_card .card-header,
.message_card_header {
  padding: 15px 15px 0px;
  background-color: transparent;
}
.message_card .card-body {
  font-size: 15px;
}

.message_card .media {
  display: -ms-flexbox;
  display: flex;
  /* -ms-flex-align: start;
  align-items: flex-start; */
}

/* .message_card .media_img_wrap {
  flex-shrink: 0;
} */
/* .message_card .media_img {
  width: 57px;
  height: 57px;
  overflow: hidden;
  object-fit: cover;
  border: 3px solid var(--color-gray-light5);
} */
.message_card .media_body {
  flex-grow: 1;
}

.message_card .card_title {
  font-weight: var(--fw-sb);
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 1.5;
}

.message_card .card_subtitle {
  color: var(--color-gray6);
  font-size: 13px;
  line-height: 1.5;
  font-weight: var(--fw-n);
}
