.consulting_session_card {
  padding: 0;
  /* border-radius: 4px; */
  overflow: hidden;
  position: relative;
  transition: 0.3s ease-in-out;
}

.consulting_session_card .card-header,
.consulting_session_card_header {
  padding: 15px;
  /* background-color: var(--color-gray-light7); */
  background-color: transparent;
  border: 0px;
}
.consulting_session_card .card-body,
.consulting_session_card_body {
  padding: 15px;
  padding-top: 0;
}

/* .consulting_session_card .media {
  display: flex;
  align-items: center;
}

.consulting_session_card .media_img_wrap {
  flex-shrink: 0;
}
.consulting_session_card .media_img {
  width: 57px;
  height: 57px;
  overflow: hidden;
  object-fit: cover;
  border: 3px solid var(--color-gray-light5);
}
.consulting_session_card .media_body {
  flex-grow: 1;
} */

.consulting_session_card .card_title {
  font-weight: var(--fw-sb);
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 1.5;
}

.consulting_session_card .card_subtitle {
  color: var(--color-gray6);
  font-size: 13px;
  line-height: 1.5;
  font-weight: var(--fw-n);
}

.consulting_session_card .custom_tag.tag_with_icon {
  padding: 2px 10px 2px 2px;
  font-size: 13px;
}
.consulting_session_card .custom_tag.tag_with_icon svg {
  font-size: 19px;
}

.dark_theme .consulting_session_card .card-body .bg_lightgray {
  background-color: var(--color-gray3);
  color: #fff;
}

.dark_theme .consulting_session_card #view_session_transactions table tr td {
  color: #fff;
}
