/* .web_utility_card {

} */

.web_utility_card .quote_wrap {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 12px solid var(--color-white);
  background: linear-gradient(
    to right,
    var(--color-primary-light1),
    var(--color-primary-dark1)
  );
  margin-top: -70px;
  margin-left: -20px;
  position: relative;
}
.web_utility_card.press_card .quote_wrap img {
  height: 52px;
  width: auto !important;
  -webkit-filter: brightness(0) invert(1);
  -moz-filter: brightness(0) invert(1);
  -o-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.web_utility_card :global(.card-body) p {
  font-size: 14px;
  font-weight: var(--fw-l);
  color: var(--color-gray6);
}

.press_card .card_img {
  border-radius: 10px;
  overflow: hidden;
  height: 300px;
}
.press_card .card_img img {
  height: 100%;
  object-fit: cover;
  margin: auto;
}

.web_utility_card.press_card :global(.card-body) {
  padding: 0;
}

.press_card :global(.card-body) p {
  font-size: 14px;
  font-weight: var(--fw-l);
  color: var(--color-gray6);
}
.press_card :global(.card-body) h5 {
  font-size: 18px;
  font-weight: var(--fw-n);
  color: var(--color-gray3);
}
.press_card :global(.card-body) h5 a {
  color: inherit;
}
.press_card :global(.card-body) h5 a:hover {
  color: var(--color-primary);
}
.press_card :global(.card-footer) {
  font-size: 14px;
  font-weight: var(--fw-l);
  background-color: transparent;
  border-top: 0px;
  color: var(--color-gray6);
  padding-left: 0;
}

.web_utility_card.testimonial_card .quote_wrap img {
  height: 34px;
  width: auto !important;
  -webkit-filter: brightness(0) invert(1);
  -moz-filter: brightness(0) invert(1);
  -o-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

/* .testimonial_card {
	padding: 0rem 1.5rem;
} */
.testimonial_card .card_img {
  border-radius: 10px;
  overflow: hidden;
}
.testimonial_card .card_img img {
  height: 100%;
  object-fit: contain;
  margin: auto;
}
.testimonial_card :global(.card-body) {
  padding: 0;
}
.testimonial_card :global(.card-body) p {
  font-size: 14px;
  font-weight: var(--fw-l);
  color: var(--color-gray6);
}
.testimonial_card :global(.card-footer) {
  font-size: 14px;
  font-weight: var(--fw-l);
  background-color: transparent;
  border-top: 0px;
  color: var(--color-gray5);
  padding-left: 0;
}

.blog_card .card_img {
  border-radius: 10px;
  overflow: hidden;
  height: 220px;
}
.blog_card .card_img img {
  height: 100%;
  object-fit: cover;
  margin: auto;
}
.blog_card :global(.card-body) {
  padding: 0;
}
.blog_card :global(.card-body) h5 {
  font-size: 18px;
  font-weight: var(--fw-n);
  color: var(--color-gray3);
}
.blog_card :global(.card-body) h5 a {
  color: inherit;
}
.blog_card :global(.card-body) h5 a:hover {
  color: var(--color-primary);
}
.blog_card :global(.card-footer) {
  font-size: 14px;
  font-weight: var(--fw-l);
  background-color: transparent;
  border-top: 0px;
  color: var(--color-gray5);
  padding-left: 0;
}

.web_utility_card.blog_card .quote_wrap {
  flex-direction: column;
}
.web_utility_card.blog_card .quote_wrap .date {
  font-size: 17px;
  font-weight: normal;
  display: block;
  color: var(--color-white);
  line-height: 1.25;
}
.web_utility_card.blog_card .quote_wrap .year {
  font-size: 25px;
  display: block;
  color: var(--color-white);
  line-height: 1.25;
}
