.join_us_banner2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left;
  min-height: 400px;
}
.join_us_banner2 [class^="col-"]:first-child {
  position: relative;
  left: -80px;
  bottom: -50px;
}
.join_us_banner2 .banner_section_text {
  padding-left: 50px;
}
.join_us_banner2 .banner_section_text h2 {
  font-size: 46px;
  color: var(--color-white);
  font-weight: var(--fw-el);
  line-height: 1.35;
}

@media screen and (max-width: 991px) {
  .join_us_banner2 [class^="col-"]:first-child {
    left: 0px;
  }
  .join_us_banner2 .banner_section_text {
    padding-left: 0px;
  }
  .join_us_banner2 .banner_section_text h2 {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  .join_us_banner2 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .join_us_banner2 [class^="col-"]:first-child {
    left: 0px;
    bottom: 0;
    margin-bottom: 20px;
  }
  .join_us_banner2 .img_container img {
    height: 300px;
  }
}
