.alphabet_filter_list {
  display: flex;
  width: 100%;
}
.alphabet_filter_list_item {
  padding: 5px;
  font-size: 14px;
  color: var(--color-gray6);
  cursor: pointer;
  position: relative;
  border-radius: 2px;
}
.alphabet_filter_list_item:not(:last-child)::before {
  content: " | ";
  display: inline-block;
  color: var(--color-gray9);
  position: absolute;
  right: -1px;
  top: 4px;
}
.alphabet_filter_list_item:hover {
  color: var(--color-black);
  background-color: var(--color-gray-light4);
}
