.footer_sect {
  background-color: var(--color-gray-light7);
}
.footer_sect .row > .col:not(:last-child) {
  border-right: 1px solid var(--color-gray-light5);
}
.footer_card {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--color-gray6);
}
.footer_card .card-body {
  padding: 20px 15px;
}
.footer_card h6 {
  font-size: inherit;
  font-weight: var(--fw-sb);
}
.footer_card li {
  margin-bottom: 10px;
}
.footer_card li a {
  color: var(--color-gray6);
}
.footer_card li a:hover {
  text-decoration: none;
  color: var(--color-primary);
}
.follow_us_wrap {
  font-weight: var(--fw-sb);
  align-items: center;
}
.follow_us_wrap a {
  font-size: 40px;
  color: var(--color-gray9);
  padding-left: 10px;
}
.follow_us_wrap a:hover {
  color: var(--color-primary);
}

.follow_us_wrap a.youtube_link:hover {
  color: var(--color-youtube);
}
.follow_us_wrap a.insta_link:hover {
  color: var(--color-gray9);
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}
.footer_card.copyright_info {
  height: 100%;
  justify-content: center;
  align-items: center;
}

.footer_card.copyright_info .card-body {
  flex-grow: 0;
  text-align: center;
}
.footer_card.copyright_info p {
  font-size: inherit;
}

@media screen and (max-width: 991px) {
  .footer_card.copyright_info p br {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .footer_sect .row > .col:not(:last-child) {
    border-right: 0px solid var(--color-gray-light5);
    border-bottom: 1px solid var(--color-gray-light5);
  }
}
