.youtube_link_card {
  /* color: var(--color-gray6); */
  cursor: pointer;
}
.youtube_link_card .icon_wrap {
  width: 50px;
  height: 50px;
  background-color: var(--color-youtube);
  transition: 0.2s ease;
}
.youtube_link_card .icon_wrap svg {
  color: var(--color-white);
  font-size: 30px;
}
.youtube_link_card:hover .icon_wrap {
  transform: translateY(-3px);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
.youtube_link_card:hover p {
  color: var(--color-youtube);
}

.dark_theme .youtube_link_card {
  background-color: var(--color-gray-light8);
}
