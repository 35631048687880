.faq_accordion .accordion-item .text_list ul {
  list-style-type: none;
  padding-left: 0;
}

.faq_accordion .accordion-item .text_list ul li {
  position: relative;
  padding: 0;
  padding-left: 25px;
  margin-bottom: 10px;
  line-height: 1.5;
}
.faq_accordion .accordion-item .text_list ul li:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--color-secondary);
  position: absolute;
  left: 0;
  top: 7px;
  display: inline-block;
}
