.footer_sect {
  background-color: var(--color-gray-light7);
  padding: 30px 0;
  color: var(--color-gray7);
}
.dark_theme .footer_sect {
  background-color: var(--color-gray3a);
}
.footer_sect p {
  margin-bottom: 0;
}
.footer_sect img.footer_logo {
  position: absolute;
  top: -60px;
  right: 0;
}
.footer_sect .container {
  position: relative;
}
/*********************/

@media screen and (max-width: 767px) {
  .footer_sect img.footer_logo {
    position: absolute;
    top: -10px;
    right: 10px;
    width: 40px;
  }
  .footer_sect p {
    font-size: 13px;
  }
}

@media screen and (max-width: 450px) {
  .footer_sect img.footer_logo {
    top: 0px;
  }
}
