.custom_card.thread_card {
  overflow: hidden;
  /* background-color: var(--color-golden-lighter); */
}
.custom_card.thread_card .card-header {
  background-color: transparent;
  /* padding-bottom: 5px; */
}
.custom_card.thread_card .card-header .card-title {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: var(--fw-n);
  color: var(--color-gray9);
}

.forum_card .thread_card .media_icon_wrap {
  background-color: var(--color-golden-light);
  width: 45px;
  height: 45px;
}
.thread_card .media_icon_wrap svg {
  font-size: 20px;
  color: var(--color-gray-light7);
}

.custom_card.thread_card .custom_card .card-header {
  padding-bottom: 15px;
}

.custom_card.thread_card .card-footer {
  background-color: transparent;
  padding: 15px;
}

/* .custom_card.thread_card .comment_card .media_icon_wrap {
  width: 36px;
  height: 36px;
} */
/* .comment_list_wrap {
  max-height: 400px;
  overflow-y: scroll;
  padding: 10px 10px 120px;
}
.comment_list_wrap::-webkit-scrollbar {
  display: none;
  width: 4px;
  background-color: transparent;
}

.comment_list_wrap:hover::-webkit-scrollbar {
  display: block;
}

.comment_list_wrap::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: var(--color-gray-light3);
}

.comment_list_wrap::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-primary-light);
}

.comment_list_wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0),
    inset -1px -1px 1px rgba(0, 0, 0, 0);
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0),
    inset -1px -1px 1px rgba(0, 0, 0, 0);
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.1);
} */
