.dark_theme .owl-carousel.bg_lightgray {
  background-color: var(--color-gray3);
}
.checkbox_card {
  text-align: center;
  background-color: transparent;
  margin-bottom: 10px;
}

.dark_theme .checkbox_card {
  background-color: transparent;
}

.checkbox_card label {
  margin-bottom: 0;
  cursor: pointer;
}
/* .checkbox_card label input {
  display: none;
} */
.checkbox_card input {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.checkbox_card label .card-body {
  border: 1px solid transparent;
  transition: 0.25s ease;
  font-size: 13px;
  padding: 10px 5px;
  position: relative;
  border-radius: 3px;
}
.checkbox_card label .card-body:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-primary);
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
}
.checkbox_card label .card-body img {
  height: 40px;
  width: auto;
  margin-bottom: 10px;
  transition: 0.25s ease;
}
.checkbox_card label .checkbox_title {
  color: inherit;
}
/* .checkbox_card label input:checked ~ .card-body {
  border-color: var(--color-primary);
  background-color: transparent;
  color: var(--color-primary-dark1);
}
.checkbox_card label input:checked ~ .card-body:before {
  opacity: 1;
  transform: scale(1);
}
.checkbox_card label input:checked ~ .card-body img {
  transform: scale(1.1);
} */

.checkbox_card input:checked ~ label .card-body {
  border-color: var(--color-primary);
  background-color: transparent;
  color: var(--color-primary-dark1);
}
.checkbox_card input:checked ~ label .card-body:before {
  opacity: 1;
  transform: scale(1);
}
.checkbox_card input:checked ~ label .card-body img {
  transform: scale(1.1);
}
