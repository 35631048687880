.section_heading {
  display: block;
  line-height: 1.1;
  position: relative;
}

.heading_extra_large {
  font-size: 90px;
  font-weight: var(--fw-sb);
  color: var(--color-primary);
  background: linear-gradient(
    120deg,
    var(--color-primary-light1),
    var(--color-primary-dark1)
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 10px;
}

.heading_large {
  font-size: 46px;
  color: var(--color-gray3);
}
.heading_medium {
  font-size: 32px;
  color: var(--color-gray3);
}
.heading_small {
  font-size: 22px;
  color: var(--color-gray3);
}
.heading_extra_small {
  font-size: 18px;
  color: var(--color-gray3);
}

@media screen and (max-width: 1199px) {
  .heading_extra_large {
    font-size: 60px;
  }
}

@media screen and (max-width: 767px) {
  .heading_extra_large {
    font-size: 40px;
  }
}
