.video_container {
  position: relative;
  height: 360px;
  border: 10px solid var(--color-gray1);
  box-shadow: 0 1px 10px rgb(0 0 0 / 50%);
  background-color: rgba(0, 0, 0, 0.9);
}
.video_container video {
  width: 100%;
  height: 100%;
  -webkit-object-fit: contain;
  object-fit: contain;
  -webkit-object-position: top;
  object-position: top;
  border: 0px;
}

@media screen and (max-width: 767px) {
  .video_container.page_banner {
    margin-top: 30px;
  }
}
