.xp_info_card .card-header {
  background-color: var(--color-primary-light2);
  padding: 15px;
}
.dark_theme .card.xp_info_card .card-header {
  background-color: var(--color-primary-light2);
  color: #fff;
}
.xp_info_card .card-body {
  padding: 15px;
}
.xp_info_card .list-group-item {
  padding: 4px 0;
  border: 0;
  background-color: transparent;
}
.dark_theme .xp_info_card .list-group-item {
  color: var(--color-gray3);
}
.xp_info_card .list-group-item:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-light4);
}

.xp_rank_card .card-header {
  background-color: var(--color-gray-light5);
  padding: 15px;
}
.dark_theme .card.xp_rank_card .card-header {
  background-color: var(--color-gray3a);
  color: #fff;
}

.xp_rank_card .card-body {
  padding: 15px;
}

.xp_rank_card .card-header .list-group-item {
  padding: 5px 0;
}

.xp_rank_card .list-group-item {
  padding: 15px 0;
  border: 0;
  background-color: transparent;
}
.xp_rank_card .list-group-item:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-light4);
}
