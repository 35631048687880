.theme-modal.update-settings-modal .modal-body .form_style .input-group {
  margin-bottom: 0;
}
.theme-modal.update-settings-modal
  .modal-body
  .form_style
  .input-group
  .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding-left: 15px;
  padding-right: 15px;
}
.theme-modal.update-settings-modal .modal-content {
  /* background-color: var(--color-gray-light7); */
  /* background-color: #fffae9; */
  border-radius: 14px;
}
.theme-modal.update-settings-modal .modal-header {
  background: transparent;
  color: #004e89;
  border-bottom: 0;
  padding: 20px 25px;
}
.theme-modal.update-settings-modal .modal-body {
  padding: 20px 25px;
}

.theme-modal.update-settings-modal .heading-icon {
  width: 54px;
  height: 54px;
  background-color: #a6e0fb;
  color: #004e89;
  margin-left: -3px;
  position: relative;
}
.theme-modal.update-settings-modal .heading-icon::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
  -webkit-animation: ripple 1s linear infinite 0.2s;
  -o-animation: ripple 1s linear infinite 0.2s;
  animation: ripple 1s linear infinite 0.2s;
}

.theme-modal.update-settings-modal .modal-helper-text {
  color: var(--color-gray4);
  font-size: 16px;
  /* font-weight: var(--fw-sb); */
}

.theme-modal.update-settings-modal .btn-close {
  width: 32px;
  height: 32px;
  align-self: flex-start;
}
.theme-modal.update-settings-modal .modal-header .btn-close:before,
.theme-modal.update-settings-modal .modal-header .btn-close:after {
  height: 16px;
  width: 2px;
}

.theme-modal.update-settings-modal .modal-header .btn-close:hover {
  background-color: var(--color-gray-light4);
  opacity: 1;
  border-color: var(--color-gray-light4);
}

.theme-modal.update-settings-modal .modal-header .btn-close:hover:before,
.theme-modal.update-settings-modal .modal-header .btn-close:hover:after {
  background-color: var(--color-gray9);
}

.dark_theme .theme-modal.update-settings-modal .modal-header {
  background: var(--color-gray25);
  color: #a6e0fb;
}

.dark_theme .theme-modal.update-settings-modal .bg_primary_light2 {
  color: var(--color-gray3);
}
.dark_theme .theme-modal.update-settings-modal .text_mediumgray {
  color: #fff;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(190, 234, 255, 0.45),
      0 0 0 1px rgba(190, 234, 255, 0.45), 0 0 0 8px rgba(190, 234, 255, 0.45);
    box-shadow: 0 0 0 0 rgba(190, 234, 255, 0.45),
      0 0 0 1px rgba(190, 234, 255, 0.45), 0 0 0 8px rgba(190, 234, 255, 0.45);
  }
  100% {
    -webkit-box-shadow: 0 0 0 1px rgba(190, 234, 255, 0.45),
      0 0 0 8px rgba(190, 234, 255, 0.45), 0 0 0 18px rgba(190, 234, 255, 0);
    box-shadow: 0 0 0 1px rgba(190, 234, 255, 0.45),
      0 0 0 8px rgba(190, 234, 255, 0.45), 0 0 0 18px rgba(190, 234, 255, 0);
  }
}
@-o-keyframes ripple {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(190, 234, 255, 0.45),
      0 0 0 1px rgba(190, 234, 255, 0.45), 0 0 0 8px rgba(190, 234, 255, 0.45);
    box-shadow: 0 0 0 0 rgba(190, 234, 255, 0.45),
      0 0 0 1px rgba(190, 234, 255, 0.45), 0 0 0 8px rgba(190, 234, 255, 0.45);
  }
  100% {
    -webkit-box-shadow: 0 0 0 1px rgba(190, 234, 255, 0.45),
      0 0 0 8px rgba(190, 234, 255, 0.45), 0 0 0 18px rgba(190, 234, 255, 0);
    box-shadow: 0 0 0 1px rgba(190, 234, 255, 0.45),
      0 0 0 8px rgba(190, 234, 255, 0.45), 0 0 0 18px rgba(190, 234, 255, 0);
  }
}
@keyframes ripple {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(190, 234, 255, 0.45),
      0 0 0 1px rgba(190, 234, 255, 0.45), 0 0 0 8px rgba(190, 234, 255, 0.45);
    box-shadow: 0 0 0 0 rgba(190, 234, 255, 0.45),
      0 0 0 1px rgba(190, 234, 255, 0.45), 0 0 0 8px rgba(190, 234, 255, 0.45);
  }
  100% {
    -webkit-box-shadow: 0 0 0 1px rgba(190, 234, 255, 0.45),
      0 0 0 8px rgba(190, 234, 255, 0.45), 0 0 0 18px rgba(190, 234, 255, 0);
    box-shadow: 0 0 0 1px rgba(190, 234, 255, 0.45),
      0 0 0 8px rgba(190, 234, 255, 0.45), 0 0 0 18px rgba(190, 234, 255, 0);
  }
}
