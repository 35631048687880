/** Main Banner **/
/* .split_banner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
} */
.signup_page {
  height: 100vh;
  min-height: 1150px;
  margin-top: -90px;
  background-color: var(--color-white);
}

/* .split_item {
  height: 100%;
  display: flex;
}

.split_item.split_item_70 {
  justify-content: flex-end;
  width: 70%;
}
.split_item.split_item_30 {
  width: 30%;
} */
/* .split_banner .split_item.split_item_30 .card {
  max-width: 400px;
  width: 100%;
  padding: 40px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  margin-left: -100px;
  align-self: center;
} */

.signup_page .split_item_content {
  width: 100%;
  max-width: 860px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.signup_page .split_item_content h1 {
  font-family: "Helvetica Neue";
  color: var(--color-white);
  font-size: 36px;
  font-weight: var(--fw-l);
  line-height: 1.2;
}
.signup_page .split_item_content h1 span {
  display: block;
}
.signup_page .split_item_content h1 .line1 {
  font-size: 65px;
  font-weight: var(--fw-b);
}

.signup_page .split_item.split_item_70 .dream_types_wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

/* .main_banner .container,
.main_banner .row {
  height: 100%;
}

.main_banner .container {
  padding-top: 120px;
} */

.split_banner.signup_page .split_item.split_item_70 .card_dream_type {
  max-width: 170px;
  padding: 10px;
  margin-left: 0;
  background-color: transparent;
  text-align: center;
  color: var(--color-white);
  box-shadow: none;
}
.split_banner.signup_page
  .split_item.split_item_70
  .card_dream_type
  .icon_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border: 2px solid var(--color-white);
  margin: auto;
}
.split_banner.signup_page
  .split_item.split_item_70
  .card_dream_type
  .icon_wrap
  img {
  height: 50px;
  width: auto;
}
.split_banner.signup_page .split_item.split_item_70 .card_dream_type p {
  font-size: 14px;
  font-weight: var(--fw-l);
  margin-top: 20px;
  letter-spacing: 1px;
  margin-bottom: 0;
  transition: 0.2s ease;
}
.split_banner.signup_page .split_item.split_item_70 .card_dream_type:hover p {
  color: var(--color-black);
}

/*************/
@media screen and (min-width: 1681px) {
  .split_item.split_item_70 {
    background-size: cover;
  }
}

@media screen and (max-width: 1440px) {
  .split_item.split_item_70 {
    background-size: cover;
  }
}
@media screen and (max-width: 1366px) {
  .split_item_content {
    max-width: 830px;
  }
}

@media screen and (max-width: 1199px) {
  .signup_page .split_item_content {
    max-width: 710px;
    padding-right: 100px;
  }
  .signup_page .split_item_content h1 {
    font-size: 28px;
  }
  .signup_page .split_item_content h1 .line1 {
    font-size: 58px;
  }
}

@media screen and (max-width: 1024px) {
  .signup_page .split_item_content {
    padding-left: 30px;
  }
  .signup_page.split_banner .split_item.split_item_30 .card {
    padding: 30px 20px;
    margin-left: -50px;
  }
}

@media screen and (max-width: 991px) {
  .signup_page.split_banner {
    flex-wrap: wrap;
    height: auto;
    padding-bottom: 60px;
  }
  .signup_page .split_item_content {
    max-width: 720px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  .signup_page.split_banner .split_item.split_item_70,
  .signup_page.split_banner .split_item.split_item_30 {
    width: 100%;
    height: auto;
  }
  .signup_page .split_item.split_item_70 .dream_types_wrap {
    padding-top: 0;
  }

  .signup_page .split_item_content h1 {
    font-size: 24px;
  }
  .signup_page .split_item_content h1 .line1 {
    font-size: 42px;
  }
  .split_banner.signup_page
    .split_item.split_item_70
    .card_dream_type
    .icon_wrap {
    width: 80px;
    height: 80px;
  }
  .split_banner.signup_page
    .split_item.split_item_70
    .card_dream_type
    .icon_wrap
    img {
    height: 40px;
    width: auto;
  }

  .signup_page.split_banner .split_item.split_item_70 {
    justify-content: center;
    padding: 100px 0 120px;

    /* background-position: bottom -100px center; */
    background-size: cover;
    background-repeat: no-repeat;
  }
  .signup_page.split_banner .split_item.split_item_30 {
    padding-bottom: 30px;
  }
  .signup_page.split_banner .split_item.split_item_30 .card {
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
    max-width: 720px;
  }
}

@media screen and (max-width: 767px) {
  .signup_page .split_item_content {
    max-width: 540px;
  }

  .signup_page.split_banner .split_item.split_item_30 .card {
    max-width: 540px;
  }
}

@media screen and (max-width: 576px) {
  .signup_page .split_item_content {
    max-width: 100%;
  }
  .signup_page.split_banner .split_item.split_item_30 {
    padding-bottom: 30px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  .signup_page.split_banner .split_item.split_item_30 .card {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .signup_page .split_item_content h1 {
    font-size: 20px;
  }
  .signup_page .split_item_content h1 .line1 {
    font-size: 34px;
  }

  .split_banner.signup_page
    .split_item.split_item_70
    .card_dream_type
    .icon_wrap {
    width: 50px;
    height: 50px;
  }
  .split_banner.signup_page
    .split_item.split_item_70
    .card_dream_type
    .icon_wrap
    img {
    height: 24px;
    width: auto;
  }
  .split_banner.signup_page .split_item.split_item_70 .card_dream_type p {
    white-space: nowrap;
    font-size: 13px;
  }
}
