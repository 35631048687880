/* .counter_card {
  cursor: pointer;
} */
.counter_card svg {
  transition: 0.35s ease-in-out;
}

.counter_card:hover svg {
  fill: var(--color-primary-light);
}
