.about_section {
  padding: 200px 0;
  position: relative;
}
.about_section .container {
  position: relative;
}
.about_section .bg_img {
  position: absolute;
  right: 0;
  top: -100px;
  max-width: 1400px;
}

@media screen and (max-width: 991px) {
  .about_section {
    padding: 100px 0;
  }
  .about_section .bg_img {
    top: -30px;
    max-width: 900px;
  }
}

@media screen and (max-width: 767px) {
  .about_section {
    padding: 50px 0 250px;
  }
  .about_section .bg_img {
    top: 120px;
    max-width: 750px;
    right: -50px;
  }
}

@media screen and (max-width: 576px) {
  .about_section .bg_img {
    top: 170px;
    max-width: 700px;
    right: 20px;
  }
}
/* @media screen and (max-width: 480px) {
  
} */
