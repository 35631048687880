.custom_accordion > .card.accordion_card > .card-header {
  padding: 0;
  border-bottom: 0px;
  background-color: var(--color-white);
}
.custom_accordion > .accordion-item {
  border: 0;
  border-radius: 15px;
}
.dark_theme .custom_accordion > .accordion-item {
  background-color: var(--color-gray25);
  color: #fff;
}

.dark_theme .custom_accordion > .card.accordion_card > .card-header {
  background-color: var(--color-gray23);
}
/* .custom_accordion.dahboard_accordion > .card.accordion_card > .card-header {
	background-color: var(--color-white);
} */
.custom_accordion .accordion-header .accordion-button {
  padding: 0 20px 0 20px;
  height: 60px;
  font-size: 16px;
  font-weight: var(--fw-sb);
  text-align: left;
  color: var(--color-gray6);
  display: flex;
  align-items: center;
}

.custom_accordion.dashboard_accordion .card-header .btn,
.custom_accordion .accordion-header .accordion-button {
  color: var(--color-primary-light);
  background-color: transparent;
  box-shadow: none;
}
.custom_accordion .accordion-header .accordion-button::after {
  content: "\f067";
  font-family: FontAwesome;
  background-image: none;
  border: 3px solid var(--color-gray-light3);
  border-radius: 50%;
  color: var(--color-gray-light3);
  width: 26px;
  height: 26px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom_accordion .accordion-header .accordion-button:not(.collapsed)::after {
  content: "\f068";
  font-family: FontAwesome;
  background-image: none;
  transform: none;
}

.custom_accordion .card-header .btn img,
.custom_accordion .accordion-header .accordion-button img {
  margin-right: 10px;
}

.custom_accordion .card-header .btn:focus,
.custom_accordion .accordion-header .accordion-button:focus {
  outline: none;
  box-shadow: none;
}
.custom_accordion .collapse.show {
  border-top: 1px solid var(--color-gray-light5);
}
.dark_theme .custom_accordion .collapse.show {
  border-top: 1px solid var(--color-gray3a);
}
