:root {
  --color-primary: #39b3ed;
  --color-primary-light: #50b4e2;
  --color-primary-light1: #4dc0f7;
  --color-primary-light2: #34c3d0;
  --color-primary-lighter: #beeaff;
  --color-primary-lighter2: #e2f5ff;
  --color-primary-dark1: #0991f6;

  --color-palegreen: #37c2ce;

  --color-secondary: #3f5d9a;
  --color-secondary-light: #507ec0;
  --color-secondary-dark: #284785;

  --color-black: #000;
  --color-white: #fff;

  --color-gray1: #111;
  --color-gray15: #151515;
  --color-gray2: #222;
  --color-gray25: #252525;
  --color-gray3: #333;
  --color-gray3a: #3a3a3a;
  --color-gray4: #444;
  --color-gray5: #555;
  --color-gray6: #666;
  --color-gray7: #777;
  --color-gray8: #888;
  --color-gray9: #999;

  --color-gray-light1: #aaa;
  --color-gray-light2: #bbb;
  --color-gray-light3: #ccc;
  --color-gray-light4: #ddd;
  --color-gray-light5: #eee;
  --color-gray-light6: #f6f6f6;
  --color-gray-light7: #faf7f7;
  --color-gray-light8: #f2f2f2;

  --color-dreams: #f07b4d;
  --color-events: #d78caa;
  --color-thoughts: #d3af61;
  --color-outofbody: #78c17a;

  --color-ad-blue: #38c0ef;
  --color-ad-purple: #e1bbe7;
  --color-ad-purple-dark: #d6b8da;
  --color-ad-purple-light: #eedaf1;
  --color-ad-yellow: #e8e687;
  --color-ad-green: #70c37a;
  --color-ad-blue-light: #89e7e4;
  --color-ad-orange: #ffb278;

  --color-danger: #ff6248;
  --color-danger-dark: #fd4a2d;
  --color-danger-light: #ff755e;
  --color-danger-lighter: #ffded8;
  --color-danger-lighter2: #ffe3de;
  --color-success: #70c37a;
  --color-success-light: #87e291;
  --color-success-lighter1: #b9eebf;
  --color-success-lighter2: #caf8cf;
  --color-success-dark: #4b9754;

  --color-youtube: #ff0000;
  --color-golden: #ffc709;
  --color-golden-dark: #eeb600;
  --color-golden-darker: #deab04;
  --color-golden-light: #f5d56b;
  --color-golden-lighter: #ffecad;
  --color-facebook: #3b5998;

  --fw-el: 100;
  --fw-l: 300;
  --fw-n: 400;
  --fw-sb: 500;
  --fw-b: 600;
  --fw-eb: 700;
}

/* html {
  font-size: 62.5%;
} */

body {
  /* font-family: "Roboto", sans-serif; */
  /* font-family: "Noto Sans", sans-serif; */
  /* font-family: "Poppins", sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Open Sans" "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  /* font-family: "Open Sans", sans-serif; */

  background-color: var(--color-gray-light7);
  color: var(--color-gray3);
  line-height: 1.5;
  /* font-size: 1.6rem; */
  font-size: 14px;
  font-weight: var(--fw-n);
  overflow-x: hidden;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.dark_theme {
  background-color: var(--color-gray3);
  color: var(--color-white) !important;
}

.dark_theme hr {
  border-top: 1px solid var(--color-gray6);
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: var(--color-gray-light6);
}

body::-webkit-scrollbar-thumb {
  border-radius: 1px;
  -webkit-box-shadow: 1px 8px 26px rgba(247, 176, 18, 0.6),
    -1px -8px 26px rgba(247, 176, 18, 0.6);
  box-shadow: 1px 8px 26px rgba(247, 176, 18, 0.6),
    -1px -8px 26px rgba(247, 176, 18, 0.6);
  background-color: var(--color-primary);
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-primary-light1);
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.35),
    inset -1px -1px 6px rgba(0, 0, 0, 0.35);
  box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.35),
    inset -1px -1px 6px rgba(0, 0, 0, 0.35);
  border-radius: 1px;
  background-color: #f7f7f7;
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--color-white);
  background: var(--color-primary-light1);
}

::selection {
  color: var(--color-white);
  background: var(--color-primary-light1);
}

p {
  line-height: 1.5;
  margin-bottom: 20px;
}
small,
.small {
  font-size: 0.899em;
}

a {
  color: var(--color-primary);
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

a:hover {
  color: var(--color-primary-dark1);
  text-decoration: none;
}

ul,
ol {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 20px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 38px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

.w50 {
  width: 560px;
}

/** Spacing ***/
/** Padding style **/
.pt0 {
  padding-top: 0;
}

.pb0 {
  padding-bottom: 0;
}

.pt1 {
  padding-top: 10px;
}

.pb1 {
  padding-bottom: 10px;
}

.pt2 {
  padding-top: 20px;
}

.pb2 {
  padding-bottom: 20px;
}

.pt3 {
  padding-top: 30px;
}

.pb3 {
  padding-bottom: 30px;
}

.pt4 {
  padding-top: 40px;
}

.pb4 {
  padding-bottom: 40px;
}

.pt5 {
  padding-top: 50px;
}

.pb5 {
  padding-bottom: 50px;
}

.pt6 {
  padding-top: 60px;
}

.pb6 {
  padding-bottom: 60px;
}

.pt7 {
  padding-top: 70px;
}

.pb7 {
  padding-bottom: 70px;
}

.pt8 {
  padding-top: 80px;
}

.pb8 {
  padding-bottom: 80px;
}

.pt9 {
  padding-top: 90px;
}

.pb9 {
  padding-bottom: 90px;
}

.pt10 {
  padding-top: 100px;
}

.pb10 {
  padding-bottom: 100px;
}
.p1 {
  padding: 10px;
}
.p2 {
  padding: 20px;
}
.p3 {
  padding: 30px;
}
/******************/

/** Margin style **/
.mt0 {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0;
}

.mt1 {
  margin-top: 10px;
}

.mb1 {
  margin-bottom: 10px;
}

.mt2 {
  margin-top: 20px;
}

.mb2 {
  margin-bottom: 20px;
}

.mt3 {
  margin-top: 30px;
}

.mb3 {
  margin-bottom: 30px;
}

.mt4 {
  margin-top: 40px;
}

.mb4 {
  margin-bottom: 40px;
}

.mt5 {
  margin-top: 50px;
}

.mb5 {
  margin-bottom: 50px;
}

.mt6 {
  margin-top: 60px;
}

.mb6 {
  margin-bottom: 60px;
}

.mt7 {
  margin-top: 70px;
}

.mb7 {
  margin-bottom: 70px;
}

.mt8 {
  margin-top: 80px;
}

.mb8 {
  margin-bottom: 80px;
}

.mt9 {
  margin-top: 90px;
}

.mb9 {
  margin-bottom: 90px;
}

.mt10 {
  margin-top: 100px;
}

.mb10 {
  margin-bottom: 100px;
}
/*****************/

/** Letter Spacing **/
.letter_spacing {
  letter-spacing: 1px;
}
/***************/

/** Theme Gradients & Background colors **/

.bg_grad_primary_to_secondary {
  background: linear-gradient(
    45deg,
    var(--color-palegreen),
    var(--color-secondary)
  );
}

.bg_transparent {
  background: transparent;
}
.bg_white {
  background-color: var(--color-white);
}
.bg_secondary_dark {
  background-color: var(--color-secondary-dark);
}
.bg_secondary {
  background-color: var(--color-secondary);
}
.bg_secondary_light {
  background-color: var(--color-secondary-light);
}
.bg_lightgray {
  background-color: var(--color-gray-light6);
}
.bg_primary_dark {
  background-color: var(--color-primary-dark1);
}
.bg_primary {
  background-color: var(--color-primary-light);
}
.bg_primary_light {
  background-color: var(--color-primary-lighter);
}
.bg_primary_light2 {
  background-color: var(--color-primary-lighter2);
}
.bg_success_light {
  background-color: #b9ffd5;
}
.bg_success {
  background-color: var(--color-success);
}
.bg_warning_light {
  background-color: #fdda9f;
}

/**********************/

/** Text Color **/
.text_primary {
  color: var(--color-primary);
}
.text_primary_dark {
  color: var(--color-primary-dark1);
}
.text_secondary {
  color: var(--color-secondary);
}
.text_secondary_dark {
  color: var(--color-secondary-dark);
}
.text_darkgray {
  color: var(--color-gray3);
}
.text_mediumgray {
  color: var(--color-gray6);
}
.text_gray1 {
  color: var(--color-gray1);
}
.text_gray2 {
  color: var(--color-gray2);
}
.text_gray3 {
  color: var(--color-gray3);
}
.text_gray4 {
  color: var(--color-gray4);
}
.text_gray5 {
  color: var(--color-gray5);
}
.text_gray6 {
  color: var(--color-gray6);
}
.text_gray7 {
  color: var(--color-gray7);
}
.text_gray8 {
  color: var(--color-gray8);
}
.text_gray9 {
  color: var(--color-gray9);
}
.text_lightgray {
  color: var(--color-gray-light2);
}
.text_lightgray2 {
  color: var(--color-gray-light3);
}
.text_white {
  color: var(--color-white);
}
.text_danger {
  color: var(--color-danger);
}
.text_success {
  color: var(--color-success);
}
.text_success_dark {
  color: var(--color-success-dark);
}
.text_golden {
  color: var(--color-golden-light);
}
/*****************/

/* font weight */

.fw_el {
  font-weight: var(--fw-el);
}
.fw_l {
  font-weight: var(--fw-l);
}
.fw_n {
  font-weight: var(--fw-n);
}
.fw_sb {
  font-weight: var(--fw-sb);
}
.fw_b {
  font-weight: var(--fw-b);
}

/***************/

/** Font sizes **/
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-17 {
  font-size: 17px;
}
.font-18 {
  font-size: 18px;
}
.font-19 {
  font-size: 19px;
}
.font-20 {
  font-size: 20px;
}
.font-21 {
  font-size: 21px;
}
.font-22 {
  font-size: 22px;
}
.font-23 {
  font-size: 23px;
}
.font-24 {
  font-size: 24px;
}
.font-26 {
  font-size: 26px;
}
.font-28 {
  font-size: 28px;
}
.font-30 {
  font-size: 30px;
}
.font-32 {
  font-size: 32px;
}
.font-34 {
  font-size: 34px;
}
.font-36 {
  font-size: 36px;
}
.font-38 {
  font-size: 38px;
}
.font-40 {
  font-size: 40px;
}
.font-42 {
  font-size: 42px;
}
.font-44 {
  font-size: 44px;
}
.font-46 {
  font-size: 46px;
}
.font-48 {
  font-size: 48px;
}
.font-50 {
  font-size: 50px;
}

/*****************/

/** Border radius **/

.border-radius-2 {
  border-radius: 2px;
}
.border-radius-3 {
  border-radius: 3px;
}
.border-radius-4 {
  border-radius: 4px;
}
.border-radius-5 {
  border-radius: 5px;
}
.border-radius-6 {
  border-radius: 6px;
}
.border-radius-7 {
  border-radius: 7px;
}
.border-radius-8 {
  border-radius: 8px;
}
.border-radius-9 {
  border-radius: 9px;
}
.border-radius-10 {
  border-radius: 10px;
}
.border-radius-15 {
  border-radius: 15px;
}
.border-radius-20 {
  border-radius: 20px;
}
.border-radius-25 {
  border-radius: 25px;
}
.border-radius-30 {
  border-radius: 30px;
}
.border-radius-35 {
  border-radius: 35px;
}
.border-radius-40 {
  border-radius: 40px;
}

/***************/

/** shadow style **/

/* .shadow1 {
  -webkit-box-shadow: 0 1px 6px rgba(100, 100, 100, 0.2);
  -moz-box-shadow: 0 1px 6px rgba(100, 100, 100, 0.2);
  box-shadow: 0 1px 6px rgba(100, 100, 100, 0.2);
} */
/* .shadow1 {
  -webkit-box-shadow: 0 4px 15px rgba(120, 120, 120, 0.2);
  -moz-box-shadow: 0 4px 15px rgba(120, 120, 120, 0.2);
  box-shadow: 0 4px 15px rgba(120, 120, 120, 0.2);
} */

.shadow_top1 {
  box-shadow: 0 -2px 3px rgba(120, 120, 120, 0.15);
}

.shadow1 {
  /* -webkit-box-shadow: 0 5px 15px rgba(120, 120, 120, 0.25);
  -moz-box-shadow: 0 5px 15px rgba(120, 120, 120, 0.25);
  box-shadow: 0 5px 15px rgba(120, 120, 120, 0.25); */
  box-shadow: 0 5px 25px -9px rgba(20, 20, 120, 0.25);
}
.shadow2 {
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12);
}
.shadow3 {
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12);
}
.shadow4 {
  -webkit-box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
}
.shadow5 {
  -webkit-box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
}
/*******************/

/** theme button **/
.btn_theme {
  padding: 10px 18px;
  min-width: 120px;
  text-align: center;
  font-size: 15px;
  font-weight: var(--fw-sb);
  color: #eee;
  border: 0px solid;
  letter-spacing: 0.04rem;
  background-color: #333;
  /* outline: 2px solid transparent; */
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.btn_theme:hover {
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #222;
  border-color: #222;
  /* outline: 2px solid transparent; */
}
.btn_theme:active,
.btn_theme:focus {
  outline: none;
  /* outline: 2px solid transparent; */
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-decoration: none;
  color: #eee;
  background-color: #333;
}

.btn_theme.btn_rounded {
  border-radius: 30px;
}

.btn_theme.btn_circle {
  border-radius: 50%;
  min-width: 0;
}
.btn_theme.btn_small {
  padding: 7px 10px;
  min-width: 80px;
  font-size: 14px;
}
.btn_theme.btn_large {
  padding: 16px 30px;
  min-width: 150px;
  font-size: 15px;
}
/*** Newly added css section for the new button ***/
.btn_theme.btn_ai {
  padding: 7px 10px;
  min-width: 120px;
  font-size: 14px;
}

.btn_theme.btn_auto_width {
  min-width: 0;
  width: auto;
}
.btn_theme.btn_block {
  min-width: 0;
  width: 100%;
  display: block;
}

.btn_theme.btn_icon {
  width: 32px;
  height: 32px;
  font-size: 16px;
  line-height: 1;
  padding: 0;
  min-width: 0;
}

.btn_theme.btn_icon *,
.btn_theme * {
  pointer-events: none;
}

.btn_theme.btn_icon.btn_small {
  width: 26px;
  height: 26px;
  font-size: 18px;
}
.btn_theme.btn_icon.btn_large {
  width: 40px;
  height: 40px;
  font-size: 18px;
}

.btn_theme.btn_comment_submit {
  width: 60px;
  height: 54px;
  padding: 0;
  min-width: 0;
  font-size: 24px;
}

.btn_theme.btn_shadow {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.21);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.21);
}

.btn_theme.btn_theme_gradient {
  color: #fff;
  border-color: var(--color-primary);
  background: linear-gradient(
    to right,
    var(--color-primary-light1),
    var(--color-primary-dark1),
    var(--color-primary-dark1),
    var(--color-primary-light1)
  );
  -webkit-background-size: 300% 100%;
  background-size: 300% 100%;
  background-position: 0 0;
}

.btn_theme.btn_theme_gradient:hover {
  color: #f2f2f2;
  background-position: 100% 0;
  border-color: var(--color-primary);
}

.btn_theme.btn_white {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}

.btn_theme.btn_white:hover {
  color: #111;
  background-color: #ddd;
  border-color: #ddd;
}
.btn_theme.down_icon_hidden::after {
  display: none;
}

.btn_theme.btn_primary {
  color: #004e89;
  background-color: #a6e0fb;
  /* border-color: #a6e0fb; */
  outline: 2px solid transparent;
}

.btn_theme.btn_primary:hover,
.btn_theme.btn_primary:focus,
.btn_theme.btn_primary:active {
  color: #004e89;
  background-color: #86cbeb;
  outline: 2px solid transparent;
}
.btn_theme.btn_primary[aria-expanded="true"] {
  /* outline: 2px solid #86cbeb;
  outline-offset: 2px; */
  border: 2px solid #004e89;
}

.btn_theme.btn_secondary {
  color: var(--color-secondary-dark);
  background-color: #a5bef3;
  outline: 2px solid transparent;
}

.btn_theme.btn_secondary:hover,
.btn_theme.btn_secondary:focus,
.btn_theme.btn_secondary:active {
  color: var(--color-secondary-dark);
  background-color: #8aacf3;
  outline: 2px solid transparent;
}
.btn_theme.btn_secondary[aria-expanded="true"] {
  /* outline: 2px solid #8aacf3;
  outline-offset: 2px; */
  border: 2px solid var(--color-secondary-dark);
}

.btn_theme.btn_success {
  color: #136634;
  background-color: #b9ffd5;
  outline: 2px solid transparent;
}

.btn_theme.btn_success:hover,
.btn_theme.btn_success:focus,
.btn_theme.btn_success:active {
  color: #136634;
  background-color: #96f1ba;
  outline: 2px solid transparent;
}
.btn_theme.btn_success[aria-expanded="true"] {
  /* outline: 2px solid #96f1ba;
  outline-offset: 2px; */
  border: 2px solid #136634;
}

.btn_theme.btn_info {
  color: #fff;
  background-color: #42d8e5;
  outline: 2px solid transparent;
}

.btn_theme.btn_info:hover,
.btn_theme.btn_info:focus,
.btn_theme.btn_info:active {
  color: #f2f2f2;
  background-color: #2cd3e2;
  outline: 2px solid transparent;
}
.btn_theme.btn_info[aria-expanded="true"] {
  /* outline: 2px solid #2cd3e2;
  outline-offset: 2px; */
  border: 2px solid #2cd3e2;
}

.btn_theme.btn_warning {
  color: #684609;
  background-color: #fdda9f;
  outline: 2px solid transparent;
}

.btn_theme.btn_warning:hover,
.btn_theme.btn_warning:focus,
.btn_theme.btn_warning:active {
  color: #684609;
  background-color: #edc889;
  outline: 2px solid transparent;
}
.btn_theme.btn_warning[aria-expanded="true"] {
  /* outline: 2px solid #edc889;
  outline-offset: 2px; */
  border: 2px solid #684609;
}

.btn_theme.btn_danger {
  color: #e51818;
  background-color: #ffd9d9;
  outline: 2px solid transparent;
}

.btn_theme.btn_danger:hover,
.btn_theme.btn_danger:focus,
.btn_theme.btn_danger:active {
  color: #e51818;
  background-color: #f7cbcb;
  outline: 2px solid transparent;
}
.btn_theme.btn_danger[aria-expanded="true"] {
  /* outline: 2px solid #f7cbcb;
  outline-offset: 2px; */
  border: 2px solid #e51818;
}
.btn_theme.btn_pink {
  color: #833d57;
  background-color: #ffcee2;
  outline: 2px solid transparent;
}

.btn_theme.btn_pink:hover,
.btn_theme.btn_pink:focus,
.btn_theme.btn_pink:active {
  color: #833d57;
  background-color: #f7b7d1;
  outline: 2px solid transparent;
}
.btn_theme.btn_pink[aria-expanded="true"] {
  /* outline: 2px solid #f7b7d1;
  outline-offset: 2px; */
  border: 2px solid #833d57;
}

.btn_theme.btn_light {
  color: var(--color-gray4);
  background-color: var(--color-gray-light4);
  /* border-color: var(--color-gray-light4); */
  outline: 2px solid transparent;
}
.btn_theme.btn_light:hover,
.btn_theme.btn_light:focus,
.btn_theme.btn_light:active {
  color: var(--color-gray4);
  background-color: var(--color-gray-light3);
  /* border-color: var(--color-gray-light3); */
  outline: 2px solid transparent;
}
.btn_theme.btn_light[aria-expanded="true"] {
  /* outline: 2px solid var(--color-gray-light3);
  outline-offset: 2px; */
  border: 2px solid var(--color-gray-light2);
}

.btn.btn_theme:disabled,
.btn.btn_theme:disabled:hover {
  cursor: not-allowed !important;
  pointer-events: painted;
  background: var(--color-gray-light1);
  /* opacity: 0.7; */
  color: var(--color-black);
}
/* .btn_theme.btn_gray {
  color: var(--color-gray3);
  background-color: var(--color-gray9);
  border-color: var(--color-gray9);
}

.btn_theme.btn_gray:hover {
  color: var(--color-gray2);
  background-color: var(--color-gray8);
  border-color: var(--color-gray8);
} */
.btn_theme.btn_gray {
  color: #504949;
  background-color: #d5bfbf;
  outline: 2px solid transparent;
}

.btn_theme.btn_gray:hover,
.btn_theme.btn_gray:focus,
.btn_theme.btn_gray:active {
  color: #504949;
  background-color: #c1a4a4;
  outline: 2px solid transparent;
}
.btn_theme.btn_gray[aria-expanded="true"] {
  /* outline: 2px solid #c1a4a4;
  outline-offset: 2px; */
  border: 2px solid #c1a4a4;
}

.btn_theme.btn_fb {
  color: #fff;
  background-color: var(--color-facebook);
  outline: 2px solid transparent;
}
.btn_theme.btn_fb:hover,
.btn_theme.btn_fb:focus,
.btn_theme.btn_fb:active {
  color: #fff;
  background-color: var(--color-facebook);
  outline: 2px solid transparent;
}
.btn_theme.btn_fb[aria-expanded="true"] {
  /* outline: 2px solid var(--color-facebook);
  outline-offset: 2px; */
  border: 2px solid var(--color-facebook);
}

.btn_theme.btn_apple {
  color: #fff;
  background-color: #000;
  outline: 2px solid transparent;
}
.btn_theme.btn_apple:hover,
.btn_theme.btn_apple:focus,
.btn_theme.btn_apple:active {
  color: #fff;
  background-color: #000;
  outline: 2px solid transparent;
}
.btn_theme.btn_apple[aria-expanded="true"] {
  /* outline: 2px solid #000;
  outline-offset: 2px; */
  border: 2px solid #000;
}

.btn_theme.btn_link {
  /* padding: 1rem 0; */
  padding: 10px 0;
  min-width: 0;
  border: 1px solid transparent;
  color: var(--color-gray2);
  background-color: transparent !important;
}

.btn_theme.btn_link:hover {
  color: var(--color-black);
  background-color: transparent;
}
.btn_theme.btn_link.btn_link_gray {
  color: var(--color-gray6);
}
.btn_theme.btn_link.btn_link_gray:hover,
.btn_theme.btn_link.btn_link_gray:focus,
.btn_theme.btn_link.btn_link_gray:active {
  border-color: transparent;
  background-color: transparent;
  color: var(--color-gray3);
}
.btn_theme.btn_link.btn_primary {
  color: var(--color-primary);
}
.btn_theme.btn_link.btn_primary:hover,
.btn_theme.btn_link.btn_primary:focus,
.btn_theme.btn_link.btn_primary:active {
  border-color: transparent;
  background-color: transparent;
  color: var(--color-primary-dark1);
}
.btn_theme.btn_link.btn_secondary {
  color: var(--color-secondary);
}
.btn_theme.btn_link.btn_secondary:hover,
.btn_theme.btn_link.btn_secondary:focus,
.btn_theme.btn_link.btn_secondary:active {
  border-color: transparent;
  background-color: transparent;
  color: var(--color-secondary-dark);
}

.btn_theme.btn_border {
  background-color: transparent;
  border: 1px solid var(--color-gray3);
}

.btn_theme.btn_border.btn_primary {
  border-color: var(--color-primary);
  color: var(--color-primary);
}
.btn_theme.btn_border.btn_primary:hover {
  background-color: var(--color-primary-lighter);
  color: var(--color-primary-dark1);
}
.btn_theme.btn_border.btn_danger {
  border-color: var(--color-danger);
  color: var(--color-danger);
}
.btn_theme.btn_border.btn_danger:hover {
  background-color: var(--color-danger-lighter2);
  color: var(--color-danger);
}
.btn_theme.btn_border.btn_white {
  border-color: var(--color-white);
  color: var(--color-white);
}
.btn_theme.btn_border.btn_white:hover {
  background-color: var(--color-gray-light6);
  color: var(--color-gray3);
}
.btn_theme.btn_border.btn_light {
  border-color: var(--color-gray-light3);
  color: var(--color-gray6);
}
.btn_theme.btn_border.btn_light:hover {
  background-color: var(--color-gray-light4);
  color: var(--color-gray3);
}

.btn_favorite.active,
.btn_favorite.active:hover {
  background-color: #ffedae !important;
  color: var(--color-golden-dark) !important;
  /* border-color: var(--color-golden-dark); */
}

.btn_theme.btn_setfocus {
  position: absolute;
  right: 5px;
  top: 50%;
  z-index: 100;
  width: 34px;
  height: 34px;
  line-height: 2;
  /* animation: move 1s infinite linear; */
  -webkit-animation: icon_fade 1.8s linear infinite;
  animation: icon_fade 1.8s linear infinite;
}
@-webkit-keyframes icon_fade {
  0%,
  100% {
    opacity: 0.7;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    opacity: 0.95;
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
}

@keyframes icon_fade {
  0%,
  100% {
    opacity: 0.7;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    opacity: 0.95;
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
}

.btn_theme.register_btn {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
}
.btn_theme.register_btn img {
  height: 50px;
  position: absolute;
  left: -1px;
}
.btn_wrap.btn_wrap_overflow {
  white-space: nowrap;
  overflow-x: auto;
}
.btn_wrap .btn_theme + .btn_theme {
  margin-left: 6px;
}

/*****************/

/** Split Container **/
.split_banner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.split_banner .split_item {
  height: 100%;
  display: flex;
}
.split_banner .split_item.split_item_70 {
  width: 70%;
}
.split_banner .split_item.split_item_30 {
  width: 30%;
}
.split_banner .split_item.split_item_50 {
  width: 50%;
}

.split_banner .split_item_content {
  height: 100%;
  display: flex;
  align-items: center;
}
.split_banner .split_item_content h1 {
  font-family: "Helvetica Neue";
  line-height: 1.2;
  color: var(--color-white);
}
.split_banner .split_item_content h1 span {
  display: block;
}

.split_banner .split_item .card {
  max-width: 400px;
  width: 100%;
  padding: 40px;
  box-shadow: 0 1px 1rem rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  margin-left: -60px;
  align-self: center;
}

/*****************/

/** Section & Inner Page Section Styee **/
.index_sect {
  padding: 70px 0;
  background-color: var(--color-white);
}

.inner_page {
  background-color: var(--color-white);
}

/****************/

/** Dashboard **/
.body_section {
  background-color: transparent;
  margin-top: -80px;
  position: relative;
  /* z-index: 1; */
}

@media screen and (max-width: 991px) {
  .left_bar_wrap {
    background-color: #fff;
    width: 300px;
    position: fixed;
    left: -310px;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    padding: 0;
    z-index: 1030;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
  .dark_theme .left_bar_wrap {
    background-color: var(--color-gray4);
  }
  /* .left_bar_wrap .left_bar {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.2s ease 0.2s;
    -moz-transition: 0.2s ease 0.2s;
    transition: 0.2s ease 0.2s;
  } */
  .left_bar_wrap::-webkit-scrollbar {
    display: none;
  }
  .left_bar_wrap.active {
    /* width: 300px; */
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 1px 0 10px rgba(0, 0, 0, 0.15);
  }
  /* .left_bar_wrap.active .left_bar {
    opacity: 1;
    visibility: visible;
  } */
}

/***********/

/** Drop down **/
.dark_theme .dropdown-menu {
  background-color: var(--color-gray3a);
  color: var(--color-gray-light6);
  border: 1px solid transparent;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
}
.dark_theme .dropdown-item,
.dark_theme .dropdown-item:focus,
.dark_theme .dropdown-item:hover {
  color: var(--color-gray-light6);
  background-color: transparent;
}
.dark_theme .dropdown-item.btn_theme.btn_gray,
.dark_theme .dropdown-item.btn_theme.btn_gray:focus,
.dark_theme .dropdown-item.btn_theme.btn_gray:hover {
  color: #504949;
  background-color: #d5bfbf;
}
/***************/

/** Main Tabs section **/
/* .tab_links .nav-item:not(:last-child) {
  margin-right: 1px;
} */

.tab_links .nav-justified {
  background-color: #fff;
  border: 0px;
  overflow: hidden;
  border-radius: 13px;
  /* overflow-x: auto;
  flex-wrap: nowrap;
  border-radius: 30px; */
}
.tab_links.tab_links_button .nav-justified .nav-item .nav-link {
  height: 100%;
  color: var(--color-white);
  padding: 15px 10px;
  font-size: 14px;
  border-radius: 1px;
}
.tab_links.tab_links_button .nav-justified .nav-item .nav-link.btn_theme {
  min-width: 0px;
  width: 100%;
  font-size: 14px;
  /* padding: 10px; */
  border-radius: 1px;
}

.tab_links.tab_links_button .nav-item .nav-link img {
  height: 40px;
  margin-bottom: 10px;
  pointer-events: none;
}

.tab_links.tab_links_button .nav-item .nav-link.dreams_tablink {
  background-color: var(--color-dreams);
}
.tab_links.tab_links_button .nav-item .nav-link.events_tablink {
  background-color: var(--color-events);
}
.tab_links.tab_links_button .nav-item .nav-link.thoughts_tablink {
  background-color: var(--color-thoughts);
}
.tab_links.tab_links_button .nav-item .nav-link.outofbody_tablink {
  background-color: var(--color-outofbody);
}

.tab_links .nav-item .nav-link {
  height: 100%;
  color: var(--color-white);
  padding: 15px 10px;
  font-size: 14px;
  border-radius: 1px;
  /* border-radius: 30px;
  white-space: nowrap; */
}
.tab_links .nav-item .nav-link img {
  height: 40px;
  margin-bottom: 10px;
  pointer-events: none;
}
.tab_links .nav-item .nav-link.normal_link img {
  /* height: 2.4rem; */
  height: auto;
  width: 26px;
  margin-bottom: 0;
  transition: 0.2s ease;
}
.tab_links .nav-item .nav-link.normal_link {
  /* background-color: var(--color-primary-lighter2); */
  background-color: transparent;
  color: var(--color-gray3);
  font-size: 15px;
}
.tab_links .nav-item .nav-link.dreams_tablink {
  background-color: var(--color-dreams);
}
.tab_links .nav-item .nav-link.events_tablink {
  background-color: var(--color-events);
}
.tab_links .nav-item .nav-link.thoughts_tablink {
  background-color: var(--color-thoughts);
}
.tab_links .nav-item .nav-link.outofbody_tablink {
  background-color: var(--color-outofbody);
}
.tab_links .nav-item .nav-link:hover {
  opacity: 0.85;
}
/* .tab_links .nav-item .nav-link.normal_link:hover, */
.tab_links .nav-item .nav-link.normal_link.active,
.tab_links .nav-item .nav-link.normal_link.active:hover {
  background-color: var(--color-primary-light);
  opacity: 1;
  color: var(--color-white);
}

.tab_links .nav-item .nav-link.normal_link:hover {
  background-color: transparent;
  color: var(--color-gray2);
}

/* .tab_links .nav-item .nav-link.normal_link:hover img, */
.tab_links .nav-item .nav-link.normal_link.active img {
  -webkit-filter: brightness(0) invert(1);
  -o-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

/* .main_tabs .tab-content .tab-pane {
	padding: 20px;
} */

/*****************/

/** Form style **/

.card.form_style {
  position: relative;
  max-width: 400px;
  width: 100%;
  padding: 40px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  /* margin-left: -6rem; */
  align-self: center;
}
.card.form_style.bg_light {
  background-color: var(--color-gray-light6);
}

.form_style .form-group {
  margin-bottom: 20px;
  position: relative;
}

.form_style .form-group .form-control {
  border: 1px solid #ddd;
  color: #666;
  font-size: 14px;
  border-radius: 3px;
  padding: 12px 15px;
  background-color: #fff;
  height: 48px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form_style .form-group .form-control:disabled {
  background-color: var(--color-gray-light6);
  cursor: not-allowed;
}

.form_style .form-group .react-tagsinput {
  border: 1px solid #ddd;
  color: #666;
  font-size: 14px;
  border-radius: 3px;
  padding: 5px 15px;
  background-color: #fff;
  height: auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form_style .form-group .react-tagsinput-input {
  color: var(--color-gray6);
  font-size: 14px;
  display: block;
  width: 100%;
}

.form_style .form-group .react-tagsinput-tag {
  background-color: var(--color-primary-lighter2);
  border-radius: 20px;
  border: 1px solid var(--color-primary-light1);
  color: var(--color-primary-dark1);
  display: inline-block;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0px 8px;
}

/* autocpmplete*/
.autocomplete_field {
  position: relative;
  z-index: 1;
}
.autocomplete_field .btn_icon {
  position: absolute;
  top: 8px;
  right: 10px;
  z-index: 1;
}
.autocomplete_result {
  width: 100%;
  max-height: 200px;
  position: absolute;
  left: 0;
  top: 100%;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}
.autocomplete_result::-webkit-scrollbar {
  display: none;
}
.autocomplete_result_item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: var(--color-gray6);
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid var(--color-gray-light4);
}
.autocomplete_result_item:hover {
  background-color: var(--color-gray-light5);
}

.dark_theme .form_style .form-group .form-control,
.dark_theme .form_style .form-group .react-tagsinput {
  background-color: var(--color-gray3);
  border: 1px solid var(--color-gray6);
  color: var(--color-white);
}

.form_style .form-group .react-tagsinput-remove {
  font-size: 18px;
}

.form_style .form-group.small_field .form-control {
  height: 40px;
  padding: 8px 15px;
}

.form_style .form-group.rounded_corners .form-control {
  border-radius: 30px;
}
.form_style .form-group.rounded_corners .react-tagsinput {
  border-radius: 30px;
}
.form_style .input-group.rounded_corners .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.form_style .form-group .form-control::-webkit-input-placeholder {
  color: #666;
  font-size: 14px;
}
.form_style .form-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #666;
  font-size: 14px;
  opacity: 1;
}
.form_style .form-group .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #666;
  font-size: 14px;
}
.form_style .form-group .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #666;
  font-size: 14px;
  opacity: 1;
}
.dark_theme .form_style .form-group .form-control::-webkit-input-placeholder {
  color: var(--color-gray-light6);
  font-size: 14px;
}
.dark_theme .form_style .form-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--color-gray-light6);
  font-size: 14px;
  opacity: 1;
}
.dark_theme .form_style .form-group .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--color-gray-light6);
  font-size: 14px;
}
.dark_theme .form_style .form-group .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: var(--color-gray-light6);
  font-size: 14px;
  opacity: 1;
}

.form_style .form-group .form-control:focus {
  border-color: var(--color-primary);
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.form_style .form-group .form-control:focus ~ label.label_float {
  top: -10px;
  padding: 1px 2px;
  background-color: var(--color-white);
  color: var(--color-primary);
  opacity: 1;
}

.form_style .form-group label.label_float {
  font-size: 11px;
  /* text-transform: uppercase; */
  letter-spacing: 1px;
  position: absolute;
  top: 2px;
  left: 18px;
  opacity: 0;
  display: inline-block;
  -webkit-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}
.form_style .form-group select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form_style .form-group textarea.form-control {
  resize: none;
  height: 80px;
}
.form_style .form-group.inline_radio_fields {
  padding-left: 20px;
  padding-right: 20px;
}
.form_style .form-group.inline_radio_fields .form-check-inline {
  max-width: 110px;
  font-size: 14px;
  align-items: flex-start;
  color: var(--color-gray6);
  vertical-align: top;
}
.form_style
  .form-group.inline_radio_fields
  .form-check-inline
  .form-check-input {
  margin-right: 5px;
  margin-top: 5px;
}

.form_style .form-group.radio_btn_field .form-check-inline {
  position: relative;
  /* font-size: 13px; */
  /* min-width: 180px; */
  max-width: none;
  padding: 0;
  color: var(--color-black);
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
.form_style .form-group.radio_btn_field .form-check-inline .form-check-input {
  position: absolute;
  left: 28px;
  top: 4px;
  cursor: pointer;
}
.form_style
  .form-group.radio_btn_field
  .form-check-inline
  .form-check-input
  + label {
  background: var(--color-gray-light5);
  padding: 6px 12px;
  border-radius: 30px;
  padding-left: 28px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.form_style
  .form-group.radio_btn_field
  .form-check-inline
  .form-check-input:checked
  + label {
  background: #a6e0fb;
}
.form_style
  .form-group.radio_btn_field
  .form-check-inline
  .form-check-input:disabled
  + label {
  cursor: not-allowed;
}

.form_style .form-group .form_error {
  display: block;
  font-size: 13px;
  color: #fd6464;
  position: absolute;
  left: 0;
  padding-left: 20px;
}

.form_style.rounded_corners .form-control {
  border-radius: 30px;
}

/*****************/

/** custom tooltip **/

.tooltip_badge {
  display: inline-block;
  padding: 1px 1px;
  /* border-radius: 3px; */
  /* background-color: var(--color-secondary-light); */
  color: var(--color-primary);
  cursor: pointer;
  font-weight: var(--fw-sb);
}

.custom_tooltip.show {
  opacity: 1;
}

.custom_tooltip .tooltip_title {
  padding: 0.25rem 0;
  border-bottom: 1px solid var(--color-gray-light1);
  margin-bottom: 0.5rem;
  font-weight: var(--fw-sb);
}

.custom_tooltip .tooltip-inner {
  max-width: 300px;
  max-height: 300px;
  overflow-y: auto;
  text-align: left;
}
.custom_tooltip .tooltip-inner::-webkit-scrollbar {
  width: 6px;
  background-color: var(--color-primary-dark1);
}

.custom_tooltip .tooltip-inner::-webkit-scrollbar-thumb {
  border-radius: 1px;
  -webkit-box-shadow: 1px 8px 26px rgba(247, 176, 18, 0.6),
    -1px -8px 26px rgba(247, 176, 18, 0.6);
  box-shadow: 1px 8px 26px rgba(247, 176, 18, 0.6),
    -1px -8px 26px rgba(247, 176, 18, 0.6);
  background-color: var(--color-primary-dark1);
}

.custom_tooltip .tooltip-inner::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-primary);
}

.custom_tooltip .tooltip-inner::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.35),
    inset -1px -1px 6px rgba(0, 0, 0, 0.35);
  box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.35),
    inset -1px -1px 6px rgba(0, 0, 0, 0.35);
  border-radius: 1px;
  background-color: #f7f7f7;
}

/****************/

/** Custom Tags **/

.custom_tag {
  font-size: 14px;
  font-weight: var(--fw-sb);
  padding: 7px 12px;
  border-radius: 30px;
  text-align: center;
  background-color: var(--color-gray-light5);
  color: var(--color-gray6);
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  margin-bottom: 4px;
  line-height: 1;
}
.custom_tag.tag_small {
  padding: 6px 10px;
  font-size: 13px;
}
.custom_tag.tag_large {
  padding: 9px 14px;
  font-size: 15px;
}
.custom_tag.tag_primary {
  background-color: var(--color-primary-light);
  color: #fff;
}
.custom_tag.tag_primary_light {
  background-color: var(--color-primary-lighter2);
  color: var(--color-gray4);
}
.custom_tag.tag_secondary {
  background-color: var(--color-secondary-light);
  color: #fff;
}
.custom_tag.tag_secondary_light {
  background-color: var(--color-ad-purple-light);
  color: var(--color-gray4);
}
.custom_tag.tag_success {
  background-color: var(--color-success);
  color: #fff;
}
.custom_tag.tag_success_light {
  background-color: var(--color-success-lighter2);
  color: var(--color-gray4);
}
.custom_tag.tag_warning {
  background-color: var(--color-golden-dark);
  /* color: var(--color-gray2); */
  color: #fff;
}

.custom_tag.tag_danger {
  background-color: var(--color-danger-light);
  color: #fff;
}
.custom_tag.tag_danger_light {
  background-color: var(--color-danger-lighter);
  color: var(--color-danger-dark);
}
.custom_tag.tag_dark {
  background-color: var(--color-gray3);
  color: var(--color-gray-light5);
}
.custom_tag.tag_gray {
  background-color: var(--color-gray6);
  color: var(--color-gray-light5);
}
.custom_tag.tag_purple {
  background-color: var(--color-ad-purple);
  color: var(--color-gray3);
}
.custom_tag.tag_golden {
  background-color: var(--color-golden-lighter);
  color: var(--color-gray5);
}

/***************/

/** Simple List styles **/

.inner_page .page_text ul {
  list-style-type: none;
  padding-left: 0;
}

.inner_page .page_text ul li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  line-height: 1.5;
}

.inner_page .page_text ul li:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--color-secondary);
  position: absolute;
  left: 0;
  top: 7px;
  display: inline-block;
}

.inner_page .page_text ul li > ul {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  line-height: 1.5;
}
/**************/

/* Back To Top Anchor */
.back_to_top {
  position: fixed;
  bottom: 7rem;
  right: 3rem;
  width: 4.5rem;
  height: 4.5rem;
  line-height: 4.2rem;
  text-align: center;
  display: none;
  font-size: 2rem;
  color: var(--color-white);
  background-color: var(--color-primary);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  -webkit-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.back_to_top:hover {
  background-color: var(--color-primary-dark1);
  color: var(--color-white);
  text-decoration: none;
}

.back_to_top:hover,
.back_to_top:focus {
  outline: none;
  border: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.error-state-text {
  color: red;
  font-size: 13px !important;
  margin: 0.5em 0;
}
/*******************/

/** Card common styles **/
/* .card {
  background-color: transparent;
} */
/* .card .card-body {
  background-color: #fff;
} */
.dark_theme .card,
.dark_theme .thread_card > .card-header,
.dark_theme .card > .card-header,
.dark_theme .card.comment_card,
.dark_theme .card.comment_card > .card-header {
  background-color: var(--color-gray3);
}

.dark_theme .card.counter_card {
  background-color: var(--color-gray25);
  color: #fff;
}
.dark_theme .card.counter_card * {
  color: var(--color-gray-light5);
}
.dark_theme .card.counter_card svg {
  color: inherit;
}

.dark_theme .card .list-group-item {
  color: var(--color-gray-light4);
}

.dark_theme .card.main_card > .card-header,
.dark_theme .card.main_card {
  background-color: var(--color-gray25);
}
.dark_theme .card.main_card.discover_content_card,
.dark_theme .card.main_card.messages_content_card {
  background-color: transparent;
}

/* .exp_card .card-header {
  background-color: var(--color-gray-light7);
} */

/* .dark_theme .card.exp_card .card-header {
  background-color: var(--color-gray15);
} */
.card.record_opt_card,
.card.form_card {
  background-color: #fff;
}
.dark_theme .card.record_opt_card {
  background-color: var(--color-gray25);
}

.card.checkbox_card label .card-body {
  background-color: transparent;
}

/**************/

/** Custom list **/

.custom_list.text_list .list-group-item {
  border: 0px;
}
.dark_theme .custom_list.text_list .list-group-item {
  color: #fff;
  background: transparent;
}

.settings-list .list-group-item {
  background-color: transparent;
  border: 0;
  display: flex;
  margin-bottom: 5px;
  padding: 5px;
  position: relative;
}

/***************/

/** Chart title **/

.chart-title > span {
  color: var(--color-gray25);
  background-color: var(--color-success-lighter2);
  border-radius: 5px;
}

/***********/

/** Swiper slider styles **/

.theme_swiper .swiper-button-next,
.theme_swiper .swiper-button-prev {
  width: calc(var(--swiper-navigation-size) / 44 * 34);
  height: calc(var(--swiper-navigation-size) / 44 * 34);
  color: var(--color-gray6);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.theme_swiper .swiper-button-next:after,
.theme_swiper .swiper-button-prev:after {
  font-size: 16px;
}

.theme_swiper.swiper-horizontal > .swiper-pagination-bullets,
.theme_swiper .swiper-pagination-bullets.swiper-pagination-horizontal,
.theme_swiper .swiper-pagination-custom,
.theme_swiper .swiper-pagination-fraction {
  bottom: -10px;
  /* top: -30px; */
  /* bottom: auto; */
  /* position: relative; */
}

/**************/

/** Modal styles **/
.theme-modal .btn-close {
  position: relative;
  font-size: 12px;
  line-height: 0.6;
  background-image: none;
  background-color: transparent;
  opacity: 1;
  color: var(--color-gray-light4);
  outline: none;
  padding: 0;
  /* width: 40px; */
  /* height: 40px; */
  align-self: flex-start;
  height: 32px;
  width: 32px;
  border: 2px solid var(--color-gray-light4);
  border-radius: 50%;
  font-weight: 300;
  -webkit-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.theme-modal .modal-header .btn-close:before,
.theme-modal .modal-header .btn-close:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  content: " ";
  height: 16px;
  width: 2px;
  background-color: var(--color-gray-light2);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.theme-modal .modal-header .btn-close:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
.theme-modal .modal-header .btn-close:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.theme-modal .modal-header .btn-close:hover {
  /* background-color: var(--color-primary-dark1); */
  /* opacity: 1; */
  /* border-color: var(--color-primary-dark1); */
  background-color: var(--color-gray-light4);
  border-color: var(--color-gray-light4);
  opacity: 1;
}

.theme-modal .modal-header .btn-close:focus {
  outline: none;
}
.theme-modal .modal-header {
  /* border-bottom-color: #f2f2f2; */
  /* background: linear-gradient(
    45deg,
    var(--color-primary),
    var(--color-secondary)
  ); 
  color: var(--color-white); */
  border-bottom: 0;
  background: transparent;
  color: var(--color-secondary-dark);
}
.theme-modal .modal-title {
  font-size: 18px;
  font-weight: var(--fw-sb);
}
.theme-modal.terms-info-modal .modal-title {
  font-size: 20px;
  font-weight: var(--fw-sb);
}
.theme-modal .modal-header,
.theme-modal .modal-body {
  padding: 20px;
}

.theme-modal .modal-body p {
  font-size: 16px;
  font-weight: var(--fw-l);
}
.theme-modal.terms-info-modal .modal-body p {
  font-size: 14px;
  font-weight: var(--fw-n);
}

.theme-modal .modal-content {
  border: 0px;
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 0 1px 35px rgba(0, 0, 0, 0.3);
}
.theme-modal .custom_card {
  box-shadow: none;
}

.theme-modal .custom_card .card-body {
  padding: 0;
}

.dark_theme .theme-modal .modal-header,
.dark_theme .theme-modal .modal-content .modal-header,
.dark_theme .theme-modal .modal-body {
  background-color: var(--color-gray25);
}

.dark_theme .theme-modal .modal-helper-text {
  color: var(--color-gray-light5) !important;
}

/* .success_page {
} */

.success-page .success-card .heading-icon {
  width: 54px;
  height: 54px;
  background-color: var(--color-success-light);
  color: #3a7e42;
  margin-left: -3px;
  position: relative;
}
.success-page .success-card .helper-text {
  color: var(--color-gray6);
  font-size: 15px;
  font-weight: var(--fw-sb);
}
.success-page .success-card .heading-icon::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
  /* -webkit-animation: ripple-confirm 1s linear infinite 0.2s;
  -o-animation: ripple-confirm 1s linear infinite 0.2s; */
  animation: ripple-success 1s linear infinite 0.2s;
}
@keyframes ripple-success {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(135, 226, 145, 0.35),
      0 0 0 1px rgba(135, 226, 145, 0.35), 0 0 0 8px rgba(135, 226, 145, 0.35);
    box-shadow: 0 0 0 0 rgba(135, 226, 145, 0.35),
      0 0 0 1px rgba(135, 226, 145, 0.35), 0 0 0 8px rgba(135, 226, 145, 0.35);
  }
  100% {
    -webkit-box-shadow: 0 0 0 1px rgba(135, 226, 145, 0.35),
      0 0 0 8px rgba(135, 226, 145, 0.35), 0 0 0 18px rgba(135, 226, 145, 0);
    box-shadow: 0 0 0 1px rgba(135, 226, 145, 0.35),
      0 0 0 8px rgba(135, 226, 145, 0.35), 0 0 0 18px rgba(135, 226, 145, 0);
  }
}

.failure-page .failure-card .heading-icon {
  width: 54px;
  height: 54px;
  background-color: var(--color-danger-lighter);
  color: var(--color-danger-dark);
  margin-left: -3px;
  position: relative;
}
.failure-page .failure-card .helper-text {
  color: var(--color-gray6);
  font-size: 15px;
  font-weight: var(--fw-sb);
}
.failure-page .failure-card .heading-icon::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
  /* -webkit-animation: ripple-fail 1s linear infinite 0.2s;
  -o-animation: ripple-fail 1s linear infinite 0.2s; */
  animation: ripple-fail 1s linear infinite 0.2s;
}
@keyframes ripple-fail {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(236, 25, 25, 0.35),
      0 0 0 1px rgba(236, 25, 25, 0.35), 0 0 0 8px rgba(236, 25, 25, 0.35);
    box-shadow: 0 0 0 0 rgba(236, 25, 25, 0.35),
      0 0 0 1px rgba(236, 25, 25, 0.35), 0 0 0 8px rgba(236, 25, 25, 0.35);
  }
  100% {
    -webkit-box-shadow: 0 0 0 1px rgba(236, 25, 25, 0.35),
      0 0 0 8px rgba(236, 25, 25, 0.35), 0 0 0 18px rgba(236, 25, 25, 0);
    box-shadow: 0 0 0 1px rgba(236, 25, 25, 0.35),
      0 0 0 8px rgba(236, 25, 25, 0.35), 0 0 0 18px rgba(236, 25, 25, 0);
  }
}

/**************/

/* Loading animation for the Chat GPT functionality */
.center {
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wave {
  width: 8px;
  height: 50px;
  background: linear-gradient(45deg, rgb(0, 166, 255), rgb(191, 229, 255));
  margin: 5px;
  animation: wave 1s linear infinite;
  border-radius: 50px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}
@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

/** Responsive Styles **/

@media screen and (min-width: 1681px) {
  .split_banner .split_item.split_item_70 {
    width: 65%;
  }
  .split_banner .split_item.split_item_30 {
    width: 35%;
  }
  .about_section .bg_img {
    max-width: 1600px;
  }
  .inner_banner.cratead_banner {
    background-size: cover !important;
  }
  .inner_banner.cratead_banner .card.form_style {
    bottom: -50px;
  }
}

@media screen and (max-width: 1440px) {
  .new_banner.split_banner {
    min-height: 1000px;
  }

  .new_banner.split_banner .split_item.split_item_30 {
    width: 35%;
  }
  .new_banner.split_banner .split_item.split_item_70 {
    width: 65%;
  }
  .new_banner.split_banner .split_item.split_item_70 img {
    object-fit: cover;
    height: 100%;
    width: auto;
  }
}

@media screen and (max-width: 1199px) {
  .new_banner.split_banner {
    min-height: 890px;
  }

  .new_banner.split_banner .split_item.split_item_30 {
    width: 35%;
  }
  .new_banner.split_banner .split_item.split_item_70 {
    width: 65%;
  }
  .new_banner.split_banner .split_item.split_item_70 img {
    object-fit: cover;
    height: 100%;
    width: auto;
  }
}

@media screen and (max-width: 1024px) {
  .new_banner.split_banner {
    min-height: 700px;
  }
}

@media screen and (max-width: 991px) {
  .new_banner.split_banner {
    min-height: 500px;
  }
}
@media screen and (max-width: 480px) {
  .new_banner.split_banner {
    min-height: 0px;
    height: 500px;
    align-items: stretch;
  }

  .tab_links.tab_links_button .nav-justified .nav-item .nav-link.btn_theme {
    font-size: 13px;
    padding: 8px 5px;
    white-space: nowrap;
    letter-spacing: normal;
    font-weight: var(--fw-b);
  }
  .tab_links.tab_links_button .nav-item .nav-link img {
    height: 26px;
    margin-bottom: 6px;
    pointer-events: none;
  }
}

/*********************/
